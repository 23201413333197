@import 'styles/branding/colors/main';

.lazy-background:not(.visible) {
  background-color: $ufl-white;
  background-image: unset;
}

.lazy {
  // display: none;
  opacity: 0;
}
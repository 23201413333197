@mixin generate-colors() {
  $colors: (
    'neutral-blue-2': $ufl-neutral-blue-2,
    'neutral-blue-1': $ufl-neutral-blue-1,
    'neutral-blue-3': $ufl-neutral-blue-3,
    'neutral-orange-2': $ufl-neutral-orange-2,
    'neutral-orange-1': $ufl-neutral-orange-1,
    'neutral-orange-3': $ufl-neutral-orange-3,
    'primary-blue-3': $ufl-primary-blue-3,
    'primary-blue-2': $ufl-primary-blue-2,
    'primary-blue-1': $ufl-primary-blue-1,
    'primary-blue-4': $ufl-primary-blue-4,
    'primary-blue-5': $ufl-primary-blue-5,
    'primary-orange-3': $ufl-primary-orange-3,
    'primary-orange-2': $ufl-primary-orange-2,
    'primary-orange-1': $ufl-primary-orange-1,
    'primary-orange-4': $ufl-primary-orange-4,
    'primary-orange-5': $ufl-primary-orange-5,
    'neutral-brown': $ufl-neutral-brown,
    'neutral-green': $ufl-neutral-green,
    'neutral-beige': $ufl-neutral-beige,
    'neutral-gray': $ufl-neutral-gray,
    'bright-green': $ufl-bright-green,
    'bright-purple': $ufl-bright-purple,
    'bright-orange': $ufl-bright-orange,
    'bright-red': $ufl-bright-red,
    'muted-blue': $ufl-muted-blue,
    'muted-orange': $ufl-muted-orange,
    'cyan': $ufl-cyan,
    'green': $ufl-green,
    'pale-green': $ufl-pale-green,
    'white': $ufl-white,
    'black': $ufl-black,
    'blue': $ufl-blue,
    'orange': $ufl-orange,
    'light': $ufl-light
  );

  @each $key, $color in $colors {
    .ufl-background--#{$key} {
      background-color: $color;
    }

    .ufl-text--#{$key} {
      color: $color;
    }

    .ufl-border--#{$key} {
      border-color: $color;
    }
  }
}

@include generate-colors();

.billion-dreams {
  font-family: BillionDreams;
  font-weight: 100;
}

.billion-h2 {
  color: #fa4616;
  font-size: 8.5vw;
  @media screen and (max-width: 800px) {
  }
}

@mixin ufl-anchor-to {
  position: absolute;
}

@mixin ufl-anchor-to-bottom-right {
  @include ufl-anchor-to;

  bottom: 0;
  right: 0;
}

@mixin ufl-anchor-to-bottom-left {
  @include ufl-anchor-to;

  bottom: 0;
  left: 0;
}

@mixin ufl-anchor-to-top-right {
  @include ufl-anchor-to;

  top: 0;
  right: 0;
}

@mixin ufl-anchor-to-top-left {
  @include ufl-anchor-to;

  top: 0;
  left: 0;
}

@mixin ufl-anchor-to-center-center {
  @include ufl-anchor-to;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

@mixin ufl-anchor-to-center-right {
  @include ufl-anchor-to;

  top: 50%;
  right: 0;

  transform: translate(0, -50%);
}

@mixin ufl-anchor-to-center-left {
  @include ufl-anchor-to;

  top: 50%;
  left: 0;

  transform: translate(0, -50%);
}

@mixin ufl-anchor-to-top-center {
  @include ufl-anchor-to;

  top: 0;
  left: 50%;

  transform: translate(-50%, 100%);
}

@mixin ufl-anchor-to-bottom-center {
  @include ufl-anchor-to;

  bottom: 0;
  left: 50%;

  transform: translate(-50%, -100%);
}

.ufl-center {
  @include ufl-center;
  width: 100%;
}

.ufl-text-center {
  @include ufl-text-center;
}
@mixin ufl-vertical-center {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
}

@mixin ufl-horizontal-center {
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
}

@mixin ufl-center {
  position: absolute;

  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

@mixin ufl-text-center {
  text-align: center;
}
@import "styles/tools/responsive/main";
@import "styles/tools/positioning/main";
@import "styles/third-party/normalizer/normalize";
@import "styles/third-party/font-awesome/main";
@import "styles/objects/center/main";
@import "styles/objects/lazy/main";
@import "styles/branding/main";
@import "styles/branding/typography";
@import "styles/branding/fonts";
@import "styles/objects/critical/non-critical";
@import "styles/releases/youbelong/main";
@import "styles/components/nav/mobile";
@import "styles/components/nav/desktop";
@import "styles/components/nav/bootstrap";
@import "styles/components/nav/legacy";
@import "styles/components/container/main";

@import url("https://use.typekit.net/vzr2qib.css");

@font-face {
  font-family: "billion-dreams";
  src: url("https://admissions.ufl.edu/fonts/BillionDreams.eot"),
    url("https://admissions.ufl.edu/fonts/BillionDreams.ttf") format("embedded-opentype"),
    url("https://admissions.ufl.edu/fonts/BillionDreams.woff") format("woff"),
    url("https://admissions.ufl.edu/fonts/BillionDreams.woff2") format("truetype"),
    url("https://admissions.ufl.edu/fonts/BillionDreams.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-bold";
  src: url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_bold-webfont.eot");
  src: url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_bold-webfont.eot?iefix") format("embedded-opentype"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_bold-webfont.woff") format("woff"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_bold-webfont.ttf") format("truetype"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_bold-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-book";
  src: url("https://admissions.ufl.edu/fonts/hinted-subset-Gentona-Book.eot");
  src: url("https://admissions.ufl.edu/fonts/hinted-subset-Gentona-Book.eot?#iefix") format("embedded-opentype"),
    url("https://admissions.ufl.edu/fonts/hinted-subset-Gentona-Book.woff2") format("woff2"),
    url("https://admissions.ufl.edu/fonts/hinted-subset-Gentona-Book.woff") format("woff"),
    url("https://admissions.ufl.edu/fonts/hinted-subset-Gentona-Book.ttf") format("truetype"),
    url("https://admissions.ufl.edu/fonts/hinted-subset-Gentona-Book.svg#Gentona-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-extrabold";
  src: url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_extrabold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-semibold";
  src: url("/microSiteDevelopment/globalFonts/rene_bieder_-_gentona_semibold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gentona-thin";
  src: url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_thin-webfont.eot"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_thin-webfont.ttf") format("embedded-opentype"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_thin-webfont.woff") format("woff"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_thin-webfont.woff2") format("truetype"),
    url("https://admissions.ufl.edu/fonts/rene_bieder_-_gentona_thin-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Obviously-ExtendedSemiBold";
  src: url("/microSiteDevelopment/globalFonts/Obviously-ExtendedSemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Obviously-CondensedSemiBold";
  src: url("https://use.typekit.net/pcm5wgf.css");
  font-weight: 600;
  font-style: normal;
}

// Colors
$uf-r2021-orange: #fa4616;
$uf-r2021-blue: #002c75;
$uf-r2021-dark-blue: #07317b;
$uf-r2021-teal: #11BAEE;
// AAA WCAG Compliant against #07317b
$uf-r2021-light-gray: #f8f9fa;
$uf-r2021-medium-blue: #0775ba;

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.boxtext22 {
  color: var(--paper);
  text-align: center;
  font: normal normal 600 2em Gentona;
  letter-spacing: 6px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin: 0 auto;
}

.boxclick {
  padding: 5px;
  border: 2px solid black;
}

// Styles for Hero Image
.herocontainer {
  background-repeat: no-repeat;
  height: 80vh;
  width: 100vw;
  background-position: 50%;
  background-size: cover;
  background-color: #ffffff;
  background-image: url("/assets/homepage-2021/assets/images/Group 750.png");

  @media screen and (max-width: 740px) {
    border-bottom: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.dotted-vertical-line {
  border-left: 4px dashed white;
  height: 190px;
  margin-left: 49.9%;
  display: none;
  animation: dotted-fade 2.5s forwards;
  -webkit-animation: dotted-fade 2.5s forwards;

  @media screen and (max-width: 740px) {
    height: 150px;
    margin-top: 20px;
  }
}

.dotted-vertical-line-second-section {
  border-left: 4px dashed $uf-r2021-orange;
  height: 90px;
  margin-left: 49.9%;
  display: block;
  margin-top: 10px;
  animation: dotted-fade 2.5s forwards;
  -webkit-animation: dotted-fade 2.5s forwards;

  @media screen and (max-width: 740px) {
    height: 60px;
    margin-top: 0;
  }
}

@keyframes dotted-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-me-in {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.arrow-down-home {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid $uf-r2021-orange;
  margin: 10px auto;
  opacity: 1;

  @media screen and (max-width: 740px) {
    margin: 10px auto;
  }
}

.headertext {
  margin-top: 40vh;
  text-align: center;
}

.headersubtext {
  text-align: center;
}

.headerptext {
  text-align: center;
}

.textcenter {
  text-align: center;
}

// Scroll Bar
body::-webkit-scrollbar {
  width: 11px;
}

body::-webkit-scrollbar-track {
  background: #00386c;
}

body::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 6px;
  border: 3px solid #00386c;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 6px;
  border: 3px solid #00386c;
}

// Styles for Homepage Body
body {
  visibility: visible;
  height: unset !important;
  width: 100vw;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $uf-r2021-teal #00386c;
  background-color: white;
}

.blacktext {
  color: #222;
}

html {
  scrollbar-width: thin;
  scrollbar-color: $uf-r2021-teal #00386c;
  scroll-behavior: smooth;
}

h1 {
  color: #000;
  letter-spacing: 0;
  line-height: 1.2;
  padding-top: 9px;
  font-size: 45px;
  border-bottom: 3px solid #f37021;
  padding-bottom: 19px;
  letter-spacing: 1.75px;
  font-family: "gentona-bold", Arial, sans-serif;
}

.grey-dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

p.homep {
  font-family: gentona-book, Arial, sans-serif;
  color: white;
  line-height: 1.6;
  font-size: 1.25em;
  white-space: normal;
  padding-right: 10px;
  margin-bottom: 3em;
  margin-top: 5px;

  @media screen and (max-width: 740px) {
    white-space: pre-wrap;
    margin-top: 0px;
  }
}

p.homep2 {
  font-family: gentona-book, Arial, sans-serif;
  font-size: 1.25em;
  color: white;
  line-height: 1.5;
  white-space: nowrap;

  @media screen and (max-width: 740px) {
    white-space: pre-wrap;
  }
}

p.homep6 {
  font-family: gentona-book, Arial, sans-serif;
  font-size: 1.25em;
  color: white;
  line-height: 1.5;
}

h3.homeh3 {
  font-size: 2em;
  font-family: gentona-book, Arial, sans-serif;
  color: $uf-r2021-teal;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: normal;

  @media screen and (max-width: 740px) {
    font-size: 1.5em;
    font-family: gentona-book, Arial, sans-serif;
    color: $uf-r2021-teal;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: 2px;
    white-space: pre-wrap;
  }
}

h1.homeh1 {
  font-size: 5.9rem;
  font-family: gentona-book, Arial, sans-serif;
  color: white;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 3.1px;
  line-height: 1.2;
  white-space: normal;
  border: none;
  padding: none;

  p {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 740px) {
    font-size: 40px;
    font-family: gentona-book, Arial, sans-serif;
    color: #fff;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 3.1px;
    line-height: 1.2;
    white-space: pre-wrap;
    padding-top: 0;
  }
}

.left-date-box .middle-date {
  text-align: left;
  height: 30px;
  margin-top: 7px;
}

.dark {
  color: #002c75 !important;
}

.fleximg {
  width: 100%;
}

.min-container-height {
  min-height: 70vh;
}

.visituscontainer {
  background-repeat: no-repeat;
  background-color: #002c75;
  background-position: cover;
  width: 100vw;

  @media screen and (max-width: 1020px) {
    height: 732px;
  }
}

.applycontainer {
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-position: bottom;
  background-position-x: center;
  width: 100vw;
}

.inclusioncontainer {
  background-image: url(/img/recruitment-2021/di_container_image.jpg);
  background-repeat: no-repeat;
  background-color: #002c75;
  background-position: 100%;
  width: 100vw;
  margin-bottom: 6px;
  height: 580px;

  @media screen and (max-width: 2015px) {
    background-position: 90px 0px;
  }

  @media screen and (max-width: 1000px) {
    background-image: url(/img/recruitment-2021/di_container_image.jpg);
    background-repeat: no-repeat;
    background-color: #002c75;
    background-position: bottom;
    height: 647px;
  }

  h1 {
    padding-top: 45px;
  }
}

.dreamscontainer {
  background-image: url(/img/recruitment-2021/dreamright.jpg);
  background-repeat: no-repeat;
  background-color: #002c75;
  background-position: right;
  width: 100vw;

  @media screen and (max-width: 1000px) {
    background-image: none;
    height: 735px;

    h1 {
      margin-top: 113px;
    }

    h2 {
      margin-top: 0px;
    }
  }
}

.margbut {
  margin-left: 122px;

  @media screen and (max-width: 1000px) {
    margin-left: 38px;
  }
}

.affordabilitycontainer {
  width: 100vw;
}

.studentcontainer {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
}

.nomargin {
  margin-left: 0px;
  padding-left: 0px;
}

.mobileimg {
  width: auto;
  height: 100%;
}

.margintop {
  margin-top: -5vw;
}

.margintop2 {
  margin-top: -92px;

  @media screen and (max-width: 740px) {
    margin-top: 0px;
  }
}

.statcontainer {
  background: $uf-r2021-light-gray;
  display: flex;
}

.guidetoapplyingcontainer {
  background: rgb(0, 84, 150);
  background: linear-gradient(180deg,
      rgba(0, 84, 150, 1) 0%,
      rgba(101, 186, 200, 1) 100%);
  height: 300px;
  width: 100vw;
  margin-bottom: 95px;

  .guide-to-applying {
    @media screen and (max-width: 740px) {
      margin: 0 auto;
      padding-top: 0;
    }
  }

  .guide-to-applying h1 {
    color: white;
    text-transform: uppercase;
    position: relative;
    font-size: 3.2rem;
    margin: 0;
    margin-top: 3em;
    font-family: gentona-bold, Arial, sans-serif;
    padding-bottom: 0;

    @media screen and (max-width: 740px) {
      margin-top: 20px;
    }
  }

  .vertical-line {
    @media screen and (max-width: 740px) {
      display: none;
    }

    padding: 90px 25px 120px 25px;
  }

  .free-guide {
    @media screen and (max-width: 740px) {
      margin: 0 auto;
      padding-left: 0;
      padding-top: 0;
    }

    padding: 90px 25px 120px 25px;
  }

  .free-guide p {
    color: $uf-r2021-blue;
    margin: 0;
  }

  p {
    color: #fff;
  }

  h1 {
    color: #fff;
    padding-top: 0;
  }

  button {
    color: #002c75;
    background-color: #fff;
    border-radius: 14px;
    font-size: 18px;
    border: none;
    margin: 10px;
    padding: 16px 30px 15px;
    font-weight: 700;
    letter-spacing: 2px;
    opacity: 1;
    margin-left: 0;
    margin-top: 0;
    border-radius: 14px;
    font-size: 18px;
  }

  .secret {
    margin-bottom: -5px;
  }
}

.financial {
  button {
    color: #ffffff;
    background-color: #002c75;
    border: none;
    padding: 16px 30px 15px;
    font-weight: 700;
    letter-spacing: 2px;
    opacity: 1;
    border-radius: 14px;
    font-size: 18px;
    margin-bottom: 41px;
    margin-top: 10px;
  }

  .secret {
    margin-bottom: -5px;
  }
}

button {
  border-radius: 14px;
  font-size: 17px;
  border: none;
  background: #fff;
  margin-bottom: 20px;
  padding: 15px 30px 12px 30px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #2f639d;
  opacity: 1;
}

.applybutton {
  border-radius: 20px;
  font-size: 20px;
  border: none;
  margin: 22px;
  padding: 30px 40px 25px 40px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #ffffff;
  opacity: 1;
  width: 100%;
  max-width: 351px;
  border-radius: 20px;
  font-size: 28px;
  border: none;
  padding: 20px 40px 17px 40px;
  color: #fff;
  background-color: $uf-r2021-dark-blue;
  font-family: "gentona-bold";
  letter-spacing: 2.3px;
  border: 1px solid transparent;
}

.applybutton:hover {
  border: 1px solid $uf-r2021-dark-blue;
  background-color: #fff;
  color: $uf-r2021-dark-blue;
}

.whywait {
  color: #002c75;
  padding-top: 26px;
}

.buttonblue {
  border-radius: 14px;
  font-size: 17px;
  border: none;
  background: $uf-r2021-teal;
  margin: 10px;
  padding: 15px 30px 12px 30px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #00386c;
  opacity: 1;
}

.darkbutton {
  border-radius: 14px;
  font-size: 17px;
  border: none;
  background: #002c75;
  margin: 10px;
  padding: 15px 30px 12px 30px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ffffff;
  opacity: 1;
  font-family: gentona, Arial, sans-serif;
  font-weight: 800;
}

.affordbutton {
  border-radius: 20px;
  font-size: 20px;
  border: none;
  padding: 30px 40px 25px 40px;
  color: #fff;
  background-color: #f36812;
  width: 100%;
  text-align: center;
}

.otherbutton1 {
  background: #002c75;
  color: #fff;
  font-family: gentona, Arial, sans-serif;
  font-weight: 800;
  padding: 10px 15px 10px 15px;
  margin-top: 20px;
}

.affordbuttonlearn {
  margin-bottom: 0px;
}

.buttonpaddingtop {
  margin-top: 126px;
}

.h1paddingstudent {
  padding-top: 45px;
  margin-bottom: 0;
  max-width: 480px;
}

.h1padding {
  padding-top: 45px;
  margin-bottom: 0;
}

.h1paddingafford {
  padding-top: 45px;
  margin-bottom: 0;
  font-size: 4.8rem;
}

.affordpadding {
  margin-left: 30px;
  margin-bottom: 5em;
}

.h3padding {
  margin-bottom: 9px;
}

.applyheading {
  color: #002c75;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 6rem;
}

.otherbutton {
  margin-top: 30px;
  margin-bottom: 30px !important;
}

.boldcolororange {
  color: #fa4616;
}

.padding {
  padding-bottom: 62px;
}

.buttonsvisit {
  margin-top: 65px;
  float: right;
  padding-bottom: 85px;
}

.buttonsapply {
  text-align: center;
}

.centertext {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.toppaddingbuttons {
  padding-bottom: 84px;
}

.bottompaddingbuttons {
  padding-bottom: 95px;
}

.first-container {
  background-color: black;
}

// Styles for Big Text
.smalltext {
  font-family: gentona-book, Arial, sans-serif;
  font-size: 1.7rem;
  color: #002c75;
  line-height: 1.5;
}

.text-align-right {
  text-align: right;
}

.col135 {
  width: 225px;
}

.searchrow {
  margin-top: 62px;
}

.mediumtext {
  font-family: gentona, Arial, sans-serif;
  font-size: 2.7rem;
  color: #002c75;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 2px;
}

.boldtext {
  font-weight: 900;
  font-size: 8.8rem;
  font-family: gentona, Arial, serif;

  margin-bottom: -46px;
  padding-bottom: 0.33em;
  letter-spacing: 1.5px;
  color: #002c75;
  display: inline-flex;
}

.boldtext135 {
  font-weight: 900;
  font-size: 8.8rem;
  font-family: gentona, Arial, serif;
  margin-bottom: -21px;
  letter-spacing: 1.5px;
  color: #002c75;
  display: inline-block;
}

.widthranked {
  width: 110px;
  display: inline-block;
  text-align: right;
  border-right: 5px solid #fa4616;
  padding-right: 20px;
  margin-right: 3px;
  padding-top: 2px;
  margin-top: 35px;
  height: 55px;
}

.widthnumber {
  width: 38px;
  padding-top: 20px;
}

.width6 {
  width: 78px;
}

.widthmajor {
  border-left: 6px solid #fa4616;
  height: 94px;
  margin-top: 27px;
  padding-top: 9px;
  padding-left: 20px;
  margin-left: 0px;
  padding-right: 19px;
  width: 188px;
  display: inline-flex;
  border-right: 6px solid #fa4616;
}

.widthchoices {
  width: 155px;
  padding-top: 44px;
  border-left: 5px solid #fa4616;
  height: 70px;
  margin-top: 42px;
  padding-top: 8px;
  padding-left: 20px;
  margin-left: 20px;
  display: inline-flex;
}

.width135 {
  padding-top: 19px;
  display: inline-flex;
}

.searchimg {
  padding-left: 19px;
  height: 132px;
  width: auto;
  margin-top: 11px;
}

.widthtop {
  width: 155px;
  padding-top: 25px;
  display: inline-block;
}

.padding0 {
  padding: 0;
  width: 300px;
}

.paddingNone {
  padding: 0;
}

.width-auto {
  width: auto;
}

.display-flex {
  display: flex;
}

.display-inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.widthus {
  width: 160px;
  text-align: left;
  border-left: 5px solid #fa4616;
  padding-left: 20px;
  padding-top: 2px;
  margin-top: 35px;
  height: 55px;
  display: inline-block;
}

.undertext {
  font-family: gentona-bold, Arial, sans-serif;
  font-size: 1.6rem;
  color: #002c75;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 2px;
  display: inline-flex;
  margin-top: -23px;
  padding-bottom: 26px;
}

.bigtextcontainer {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 28px;
}

.textcenter {
  text-align: center;
}

.margin-auto {
  margin: 0 auto;
}

.findatour {
  margin-top: 45px;
  margin-left: 0;
}

.learnmorepadding {
  margin-bottom: 60px;

  margin-left: 0;
}

.learnmorepaddingdiversity {
  margin-bottom: 60px;

  margin-left: 0;
}

.inclusioncontainerpadding {
  margin-left: 13em;
  max-width: 591px;
  margin-top: 0;

  @media only screen and (max-width: 1020px) {
    margin-left: 15px;
    /* background-color: rgba(1,28,117,.8); */
    padding-left: 20px;
    padding-right: 30px;
    /* padding-top: 13%; */
    height: auto;
    // /* padding-bottom: 100px;
  }
}

.bodytext {
  max-width: 550px;
}

.bodytext2 {
  max-width: 600px;
}

.moreoptions {
  font-size: 7vw;
  text-align: center;
}

.breakword {
  word-break: break-word;
}

.boldtextsmall {
  font-weight: 900;
  font-size: 4rem;
  font-family: gentona, Arial, serif;

  margin-top: 35px;
  margin-left: 6px;
  margin-bottom: 0.33em;
  padding-bottom: 0.33em;
  letter-spacing: 1.5px;
  color: #002c75;
}

.studentimg {
  max-height: 400px;
  margin-top: 11%;
  margin-left: 10%;
}

.rowbackgroundcolor {
  margin: auto !important;

  @media only screen and (max-width: 1000px) {
    background-color: rgba(1, 28, 117, 0.8);
    height: inherit;
  }
}

@media screen and (max-width: 1500px) {
  .studentimg {
    width: 91%;
    height: auto;
  }
}

@media only screen and (max-width: 1025px) {
  .mobileimg {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }

  .findatour {
    margin-bottom: 97px;
  }

  .h1paddingstudent {
    padding-top: 0px;
    padding-bottom: 0;
  }

  .studentimg {
    margin-left: 0px;
    width: auto;
  }

  .affordbuttonlearn {
    margin-bottom: 0px;
  }

  .affordbuttontop {
    margin-top: 47px;
  }

  .affordbuttonbottom {
    margin-bottom: 49px;
  }

  .learnmorepaddingdiversity {
    margin-bottom: 660px;

    margin-left: 0;
  }
}

@media only screen and (max-width: 675px) {
  .studentimg {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 750px) {
  .widthranked {
    margin-top: 0px;
  }

  .widthus {
    margin-top: 0px;
  }

  .boldtext135 {
    font-weight: 900;
    font-size: 8.8rem;
    font-family: gentona, Arial, serif;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 12px;
    letter-spacing: 1.5px;
    color: #002c75;
  }

  .searchimg {
    padding-left: 19px;
    height: auto;
    width: 90vw;
    margin-top: 11px;
  }

  .width135 {
    padding-top: 25px;
  }

  .widthmajor {
    padding-top: 0px;
    border-top: 5px solid #fa4616;
    height: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    margin-left: 0px;
    width: auto;
    display: inline-flex;
  }

  .mediumtext {
    font-family: gentona-bold, Arial, sans-serif;
    font-size: 2.5rem;
    color: #002c75;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 2px;
    margin-top: 8px;
    padding-top: 0px;
  }

  .widthchoices {
    width: auto;
    padding-top: 44px;
    border-left: 0px;
    height: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    margin-left: 0px;
    display: inline-flex;
  }

  .smalltext {
    padding-top: 24px;
  }

  .applybutton {
    margin: 0px;
    margin-bottom: 22px;
  }

  .guidetoapplyingcontainer {
    height: auto;
  }
}

// Auto-typed text style
.typing-container {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65vh;
  color: white;

  @media screen and (max-width: 740px) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    margin-top: 50vh;
  }
}

.typing-container p {
  font-size: 10rem;
  padding: 0.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 740px) {
    font-size: 3rem;
    margin-bottom: 0;
  }
}

.typing-container p span.typed-text {
  font-size: 10rem;
  padding: 0.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 740px) {
    font-size: 6rem;
    // padding-bottom: 5vh;
  }
}

.typing-container p span.typed-text.underline {
  text-decoration: underline;
}

.typing-container p span.cursor {
  display: inline-block;
  background-color: #fff;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}

.typing-container p span.cursor.typing {
  animation: none;
}

@keyframes blink {
  0% {
    background-color: #ccc;
  }

  49% {
    background-color: #ccc;
  }

  50% {
    background-color: transparent;
  }

  99% {
    background-color: transparent;
  }

  100% {
    background-color: #ccc;
  }
}

.downarrow {
  margin: 0 auto;
  display: block;
  // height: 80px;
  padding-top: 17px;
  // height: 10vh;
  width: 2.8vw;
}

@media screen and (max-width: 499px) {
  .downarrow {
    margin: 0;
    width: 5.8vw;
    margin-bottom: 8vw;
  }
}

.downarrowcollege {
  width: 234px;
  outline: none;
  text-decoration: none;
  margin-top: -2px;
}

.bigapply {
  display: block;
  margin: 0 auto;
  padding-bottom: 28px;
  width: 62px;
}

// Collegetown Gainesville Container
.collegeheader {
  width: 100vw;
  z-index: 3;

  h1 {
    font: normal normal 700 50px gentona-bold;
    letter-spacing: 3.1px;
    color: #002c75;
    text-transform: uppercase;
    opacity: 1;
    white-space: pre-wrap;
    margin-bottom: 0;
    padding-bottom: 0px;
    margin-top: 110px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.8em;
    color: #fa4616;
    font-weight: 200;
    letter-spacing: 2px;
    padding-top: 0;
    margin-top: 11px;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    color: #00529b;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 74px;
    white-space: pre-wrap;
  }
}

.collegetowncontainer {
  background-image: url(/img/recruitment-2021/ContainerImage_Gainesville_Image.jpg);
  background-repeat: no-repeat;
  background-color: #002c75;
  background-position-x: right;
  width: 100vw;

  h1 {
    text-align: left;
    font: normal normal bold 700 44px gentona;
    letter-spacing: 3.1px;
    color: #fa4616;
    opacity: 1;
    margin-bottom: 0;

    @media (max-width: 769px) {
      font-size: 40px;
      margin-left: 10vw;
    }

    margin-left: 122px;
    padding-top: 110px;
  }

  h2 {
    text-align: left;
    font: normal normal bold 31px gentona;
    letter-spacing: 3.75px;
    color: white;
    padding-top: 9px;
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 122px;

    @media (max-width: 769px) {
      font-size: 30px;
      margin-left: 10vw;
      white-space: pre-wrap;
    }
  }

  h3 {
    margin-bottom: 0;
    margin-left: 122px;

    @media (max-width: 769px) {
      margin-left: 10vw;
      white-space: pre-wrap;
    }
  }

  p {
    margin-top: 12px;
    margin-left: 122px;
    text-decoration: left;
    font-size: 20px;
    line-height: 1.5;
    font: normal normal bold 18px gentona;

    @media (max-width: 769px) {
      margin-left: 10vw;
      white-space: pre-wrap;
    }
  }

  button {
    margin-left: 122px;
    margin-top: 19px;
    border-radius: 8px;
    font-size: 17px;
    border: none;
    margin: 10px;
    padding: 9px 28px 8px;
    font-weight: 700;
    letter-spacing: 2px;
    opacity: 1;
    margin-left: 122px;

    @media (max-width: 769px) {
      margin-left: 3vh;
    }
  }

  .dot-locations span {
    color: white;
    display: block;
  }
}

.left-collegetown-section {
  margin-left: 50px;
  margin-top: 150px;
}

.collegetowncontainer .container-fluid .row .col-md-7.splerg {
  background-image: url(/img/recruitment-2021/ContainerImage_Gainesville_Florida.png);
  background-repeat: no-repeat;
  background-position-x: right;
  min-height: 590px;

  .city-name-blue {
    color: $uf-r2021-blue;
    font: normal normal bold 22px gentona-bold;
    display: block;
  }

  .city-name-blue-gville {
    color: $uf-r2021-blue;
    font: normal normal bold 27px gentona-bold;
    display: block;
  }

  .city-distance-blue {
    color: $uf-r2021-blue;
    letter-spacing: 0.75px;
    font-family: gentona-book;
    font-size: 15px;
  }

  .city-name-white {
    color: white;
    font: normal normal bold 22px gentona-bold;
    display: block;
  }

  .city-distance-white {
    color: white;
    letter-spacing: 0.75px;
    font-family: gentona-book;
    font-size: 15px;
  }

  .cluster-locations {
    position: relative;

    .tallahassee {
      position: absolute;
      text-align: center;
      right: 540px;
      top: 58px;
      letter-spacing: 0.95px;

      @media screen and (max-width: 1220px) {
        right: 5502px;
      }
    }

    .ocala {
      position: absolute;
      text-align: center;
      right: 250px;
      top: 265px;
      letter-spacing: 0.95px;
    }

    .jacksonville {
      position: absolute;
      text-align: center;
      right: 65px;
      top: 50px;
      letter-spacing: 0.95px;
    }

    .staugustine {
      position: absolute;
      text-align: center;
      right: 29px;
      top: 120px;
      letter-spacing: 0.95px;
    }

    .orlando {
      position: absolute;
      text-align: center;
      right: 140px;
      top: 365px;
      letter-spacing: 0.95px;
    }

    .tampa {
      position: absolute;
      right: 220px;
      top: 430px;
      letter-spacing: 0.95px;
    }

    .gainesville {
      position: absolute;
      text-align: center;
      right: 255px;
      top: 185px;
      letter-spacing: 1.15px;
    }
  }

  .uf-map-link {
    position: relative;

    .uf-map-link-image {
      position: absolute;
      right: 266px;
      top: -50px;
    }
  }
}

.uf-pin:hover {
  transform: scale(1.1);
}

// Collegetown Second Container
.collegetown-second-container {
  background-image: url(/img/recruitment-2021/ContainerImage_Gainesville3.png);
  background-repeat: no-repeat;
  background-color: #002c75;

  h1 {
    color: $uf-r2021-blue;

    text-align: left;
    font: normal normal bold 32px/38px Gentona;
    letter-spacing: 1.6px;
    color: #002c75;
    opacity: 1;
  }

  p {
    text-align: left;
    font: normal normal medium 15px/21px Gentona;
    letter-spacing: 0.75px;
    color: #000000;
    opacity: 1;
  }
}

.height {
  height: 590px;
}

.border {
  border: 10px solid white;
}

.left-collegetown-second-section {
  padding-left: 0;
  margin-top: -80px;

  p {
    margin-top: 10px;
    font: normal normal medium 17px/25px Gentona;
    letter-spacing: 0.85px;
    color: #f7f9fb;
    opacity: 1;
    text-align: center;
    width: 295px;
  }
}

.right-collegetown-second-section {
  padding-left: 5vw;

  h1 {
    padding-top: 25px;
  }
}

.right-collegetown-second-section-main-title {
  text-align: center;

  h1 {
    color: white;
    padding-bottom: 20px;
  }

  strong {
    color: $uf-r2021-teal;
  }
}

.extrapadding101 {
  padding-top: 18px;
}

.collegetown-second-container-card {
  width: 35rem;
  background-color: $uf-r2021-light-gray;

  img {
    max-width: 350px;
    width: 100%;
  }

  .card-body {
    min-height: 200px;
    padding: 10px 25px 10px 25px;
    background-color: $uf-r2021-light-gray;
  }
}

.aftergainesvillecontainer {
  height: 70px;
  width: 100vw;
  background-color: white;
}

.flexed-center {
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }
}

.inlinegrid22 {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 740px) {
    display: inline-grid;
  }
}

.pubs {
  a {
    font-size: 18px;
    padding-bottom: 17px;
  }

  .heyo {
    font-size: 40px;
  }
}

h2 {
  font-family: gentona, Arial, sans-serif;
  color: #fa4616;
  line-height: 1.5;
  font-weight: 900;
  letter-spacing: 0.85;
  line-height: 1.2;
  font-size: 1.9rem;
}

strong {
  color: #002c75;
}

.blue {
  color: #002c75;
}

.zeroline {
  line-height: 0.5;
  padding-left: 14px;
}

.considerfresh {
  padding-bottom: 0px;
  margin-bottom: 11px;
}

.orange {
  color: #fa4616;
}

.bold {
  font-weight: 900;
}

.appdue {
  font-weight: 900;
  font-size: 1.9rem;
}

.credith3 {
  h3 {
    margin-bottom: 0;
  }
}

.apphr {
  height: 10px !important;
  background-color: $uf-r2021-light-gray;
}

.bluecontainer {
  background: #dff3f4;
  padding: 19px 30px 25px 35px;
  margin-bottom: 30px;
}

.ufCoolGrayContainer {
  // background: #C2E3EA;
  // background: #DBF5E3;
  background: #d1ecf1;
  padding: 19px 30px 25px 35px;
  margin-bottom: 30px;
  border-radius: 25px;
  border: none;
  color: #002c75;
  font-family: 'gentona';
}

.ufCoolGrayContainer p {
  color: #002c75;
  font-family: 'gentona';
}

.ufCoolGrayContainer a:not(.button-link-dark-blue) {
  color: #07317b;
}

.ufCoolGrayContainer a:hover:not(.button-link-dark-blue),
.ufCoolGrayContainer a:focus:not(.button-link-dark-blue),
.ufCoolGrayContainer a:active:not(.button-link-dark-blue) {
  color: #000;
}

.h1consider {
  font-size: 3.5rem;
}

.h2consider {
  font-size: 2.2rem;
}

.row-containertealdot {
  display: flex;
  flex-direction: row;
}

.tealdotcontainer {
  margin-right: 30px;
}

.excitedcontainer {
  margin-right: 15px;
}

.tealdotcontainer2 {
  margin-right: 17px;
}

.tealdotwordcontainer {
  width: auto;
}

.tealdotwordcontainer3 {
  max-width: 795px;
}

.tealdotimg {
  width: 21px;
  margin-top: 20px;
}

.tealdotimg2 {
  width: 35px;
  margin-top: 14px;
}

.bigh1text {
  font-size: 4rem;
  margin-bottom: 75px;
}

.nopadding22 {
  padding-left: 0 !important;
}

.bluebutton {
  color: #fff;
  background: #084b81 0 0 no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border: none;
  font-size: 15px;
  text-transform: uppercase;
  margin-right: 10px;
}

.padtop5 {
  padding-top: 120px !important;
}

.padtop6 {
  padding-top: 79px !important;
}

.padtop7 {
  margin-top: 99px !important;
}

.padtop9 {
  padding-top: 104px;
}

.padtop10 {
  padding-top: 95px !important;
}

.belize {
  display: flex;
  margin-top: 34px;
}

.belizeimg {
  width: 72px;
  height: 79px;
  position: relative;
  bottom: 16px;
}

.collegetowngainesville {
  max-width: 847px;
  margin: 0 auto;
  text-align: center;
}

.orangebackground {
  background-color: #fa4616;
}

.orangebackgroundwhite {
  background-color: #fa4616;
  color: #fff;
}

.link {
  text-decoration: underline;
}

.font-increase {
  font-size: 1.1em;
}

.visitimgpage {
  position: absolute;
  background: #fff;
  padding: 11px 26px 10px 69px;
  color: #2f639d;
  margin-top: 54px;
  letter-spacing: 2px;
}

.tourimg {
  width: 100%;
  padding-top: 35px;
  width: 30vw;
}

.visitusimg {
  width: 100vw;
  // max-height: 50vh;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.selftour {
  background-image: linear-gradient(90deg, #06307a, $uf-r2021-teal);
  padding: 40px;
}

.bordergrad {
  background-image: linear-gradient(90deg, #06307a, $uf-r2021-teal);
}

.solidback {
  background-color: #fff;
  margin: 10px;
  padding-top: 0px;
  margin-top: 0px;
  padding-left: 30px;
}

.white {
  color: white;
}

.white2 {
  color: white;
  font-size: 2.7rem;
}

.gallerypadding {
  padding: 34px;
}

.appicons {
  display: inline;
}

.appicon {
  height: 30px;
}

.appimgs {
  height: 60px;
}


@media screen and (max-width: 500px) {
  .appicons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .appicon {
    height: 100%;
    width: fit-content;
  }

  .appimgs {
    height: 60px;
  }

  .appimgs:first-child {
    height: 60px;
    margin-left: -8px;
  }
}

.pborder2 {
  margin-bottom: 10px;
  padding-bottom: 0px;
  margin-top: 0px;
}

.pborder {
  margin-bottom: 10px;
  padding-bottom: 30px;
  margin-top: 0px;
}

.h1border {
  margin: 10px;
  padding-top: 30px;
}

.padtop1 {
  margin-top: 40px;
}

.gallerybutton {
  margin-top: 125px;
  float: right;
}

.floatleft {
  @media screen and (max-width: 740px) {
    float: left;
  }
}

.padtop2 {
  margin-top: 10px;
}

.padtop3 {
  margin-top: 78px;
}

.padtop4 {
  margin-top: 50px;
}

.pacegallery {
  margin-top: 67px;
  padding-bottom: 31px;
}

.pacequoteimg {
  z-index: -1;
  margin-bottom: -45px;
  max-width: 278px;
}

.height2 {
  height: 30px;
}

.leftquote {
  position: absolute;
  z-index: -1;
  margin-left: -61px;
  margin-top: -66px;
  transform: rotate(180deg);
  width: 175px;
}

.quotemid {
  padding-bottom: 0;
  margin-bottom: 5px;
  margin-top: 15px;
  padding-top: 0px;
}

.quotetop {
  font-size: 36px !important;
  padding-bottom: 0px;
  margin-bottom: 0;
}

.quotebottom {
  margin-top: 0px;
  font-weight: 500;
}

.rightquote {
  width: 85px;
  position: absolute;
}

.mapimg {
  box-shadow: 5px 6px 5px grey;
  width: 355px;
  margin-top: 58px;
  position: absolute;
  margin-left: -15px;
}

.two-button-group {
  a {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1159px) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 740px) {
  .two-button-group a {
    font-size: 1em;
  }
}

.nonbold {
  font-weight: 100;
}

.counselorimg {
  box-shadow: 5px 6px 5px grey;
  margin-top: 41px;
  position: absolute;
  width: 125px;
}

.appimg2 {
  width: 476px;
  position: absolute;
  margin-top: 30px;
}

.appimg3 {
  // width: 25vw;
  // margin-top: 1em;
  // box-shadow: none;
  // max-height: 25vh;
  // border-radius: 15px;
  width: 41em;
  margin-top: 1em;
  box-shadow: none;
  max-height: 16em;
  border-radius: 15px;
  height: 16em;
  max-width: 100%;
}

.floatright1 {
  float: right;
}

.bluebackground {
  background-color: #002c75;
  padding-top: 16px;
  padding-left: 54px;
  margin: 25px 0 40px 0;
}

.mapdl {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1745px) {
  .mobileimg {
    margin-left: -200px;
  }
}

@media screen and (max-width: 1545px) {
  .mobileimg {
    margin-left: -400px;
  }
}

@media screen and (max-width: 1345px) {
  .mobileimg {
    margin-left: -600px;
  }
}

@media only screen and (max-width: 1020px) {
  .mobileimg {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }

  .padtop9 {
    padding-top: 25px !important;
  }
}

@media screen and (max-width: 1020px) {
  .mapimg {
    box-shadow: none;
    width: 100%;
    margin-top: 0;
    position: inherit;
    padding: 20px;
  }

  .counselorimg {
    box-shadow: none;
    width: 100%;
    margin-top: 0;
    position: inherit;
    padding: 20px;
  }

  .appimg2 {
    width: 100%;
    position: inherit;
  }

  // .appimg3 {
  //   width: 50%;
  //   position: inherit;
  // }

  .floatright1 {
    float: none;
  }
}

@media screen and (max-width: 768px) {
  .appimg3 {
    width: 100%;
    position: inherit;
  }
}

// Blog
.blogimg {
  width: 100%;
}

.blogcontainer {
  border: 1px solid #ccc;
  height: 470px;
}

.blogbutton {
  margin: 0 auto;
  margin-top: 44px;
  margin-bottom: 20px;
}

.blogheading {
  font-weight: bold;
  font-size: 2.3rem;
  padding-left: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #06307a;
}

.blogcontent {
  padding-left: 20px;
  padding-right: 20px;
}

.threerem {
  font-size: 3rem;
}

//social
.socialbackground {
  margin-top: 40px;
  margin-bottom: 1em;
}

.socialtwo {
  height: 66px;
  margin-top: 33px;
}

.visit-social-icons {
  font-size: 3em;
  margin-left: 2em;

  @media screen and (max-width: 740px) {
    margin-left: 0;
  }
}

// Collapsible
.collapsible {
  background-color: #002c75;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 95%;
  border: none;
  text-align: left;
  outline: none;
  font-family: gentona, Arial, sans-serif;
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 900;
}

.collapsiblearrow {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-family: gentona, Arial, sans-serif;
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 900;
  border: 1px solid #002c75;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
}

.collapsibleButtonWordEmphasis {
  text-transform: uppercase;
  font-family: 'gentona-bold';
}

.collapsible:hover .collapsibleButtonWordEmphasis {
  color: #fff;
}

// .collapsible:hover, .collapsible:hover:after, .collapsible:active {
//   background-color: #002c75;
//   color: #fff;
// }

// .collapsiblearrow:active, .collapsiblearrow:hover,  .collapsiblearrow:hover:after{
//   background-color: #002c75;
//   color: #fff;
// }

.collapsible:after,
.collapsible:hover:after {
  content: '+';
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin-left: 1em;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  float: right;
}

.collapsible.active:after {
  content: '–';
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin-left: 1em;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  float: right;
}

.space {
  letter-spacing: 1.7px;
}

.arrowdown {
  margin-top: -15px;
  padding-top: 0px;
  height: 195px;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #ffffff;
  max-width: 73em;
}

.content-pace {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #ffffff;
  max-width: 73em;
}

//styling for list icons
.checkmark {
  width: 30px;
  vertical-align: middle;
  margin-top: -7px;
  padding-right: 3px;
  margin-right: 4px;
}

.solidbox {
  vertical-align: middle;
  width: 19px;
  margin-top: -5px;
  padding-right: 1px;

  margin-right: 4px;
}

.nextsteps {
  color: #000000;
  line-height: 1.5;
  font-weight: 900;
  letter-spacing: 0.85;
  line-height: 1.2;
  font-size: 29px;
}

.solidboxsmall {
  vertical-align: middle;
  width: 14px;
  margin-top: -3px;
  padding-right: 1px;

  margin-right: 4px;
}

.solidboxarrow {
  vertical-align: middle;
  width: 16px;
  margin-left: 5px;
}

.indent {
  margin-left: 43px;
}

.main-container {
  display: flex;
}

#root-apply-freshman {
  margin-top: 30vh;
  margin-left: 25vw;

  @media (min-height: 1080px) {
    margin-top: 19vh;
  }

  @media screen and (max-width: 740px) {
    margin-left: 5vw;
    margin-top: 23vh;
  }
}

.side-nav-freshman {
  margin-top: 0;
  z-index: 1;
  display: flex;
  padding-left: 8vw;
  position: sticky;
  top: 0;
  background-color: #fff;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
    position: initial;
    z-index: 6;
  }
}

.center-content-area {
  display: flex;
  width: 100vw;

  .row-container {
    display: flex;
    padding-left: 5vw;

    @media screen and (max-width: 740px) {
      padding-left: 0;
    }
  }

  .column-container-left {
    @media screen and (max-width: 740px) {
      width: 0;
    }

    @media screen and (max-width: 1300px) {
      margin-left: 0;
    }

    display: flex;
    flex-direction: column;
    width: 30vw;
    margin-left: -2.5em;
  }

  .column-container-right {
    @media screen and (max-width: 740px) {
      margin-top: 0;
      width: 100vw;
      padding-left: 0;
    }

    display: flex;
    flex-direction: column;
    width: 59vw;
    max-width: 1200px;
    margin-top: 22px;
  }

  .column-container-far-right {
    @media screen and (max-width: 740px) {
      width: 0;
    }

    display: flex;
    flex-direction: column;
    width: 10vw;
    margin-top: 35vh;
    min-height: 80vh;
  }
}

.side-link-mobile {
  display: none;

  @media screen and (max-width: 740px) {
    display: block;
    background-color: $uf-r2021-light-gray;
    margin-top: 2vh;
    height: 6vh;
    width: 100vw;
    margin-left: -8vw;
    font-family: "gentona-bold", Arial, sans-serif;

    .side-link-menu {
      display: flex;
      justify-content: center;
      font-size: 2.5vh;
      padding-top: 1.5vh;
    }

    #side-link-menu {
      display: none;
    }

    .side-link-container-mobile {
      display: flex;
      background-color: white;
      margin-top: 0;
      flex-direction: column;
      padding-left: 5vw;
      width: 100vw;

      li {
        list-style-type: none;
      }

      li a {
        color: #002c75;
        font-size: 1.7rem;
        font-family: "gentona", Arial, sans-serif;
        font-weight: 700;
      }

      li a:hover {
        background: $uf-r2021-light-gray;
      }

      li hr {
        display: block;
        border: 0;
        border-top: 2px solid #c7c7c7;
        margin: 0.8em 0;
        padding: 0;
      }
    }
  }
}

.side-link-container {
  @media screen and (max-width: 768px) {
    display: none;
  }

  display: block;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 30px;
  max-width: 330px;
  background-color: #fff;

  li {
    list-style-type: none;
  }

  .collapsible {
    background-color: white;
  }

  a {
    color: #002c75;
    background-color: white;
    font-size: 19px;
    font-family: gentona, Arial, sans-serif;
    font-weight: 700;
    position: relative;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 14px;
    min-width: 100%;
    display: inline-block;
    padding-left: 0;
  }

  a:hover {
    background-color: #d1ecf1;
    color: #002c75;
    text-decoration: none;
    outline: none;
  }

  a:active {
    background-color: #d1ecf1;
    color: #002c75;
    text-decoration: none;
    outline: none;
  }

  a:focus {
    background-color: #d1ecf1;
    color: #002c75;
    text-decoration: none;
    outline: none;
  }

  a hr {
    display: block;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 2px solid #c7c7c7;
    margin: 0;
    padding: 0;
  }

  li a {
    color: #002c75;
    font-size: 19px;
    font-family: gentona, Arial, sans-serif;
    font-weight: 700;
    position: relative;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 14px;
    padding-left: 0.3em;
    min-width: 100%;
    display: inline-block;
  }

  li a:hover {
    background-color: #d1ecf1;
    text-decoration: none;
    outline: none;
  }

  li a:active {
    background-color: #d1ecf1;
    text-decoration: none;
    outline: none;
  }

  li a:focus {
    background-color: #d1ecf1;
    text-decoration: none;
    outline: none;
  }

  li hr {
    display: block;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 2px solid #c7c7c7;
    margin: 0;
    padding: 0;
  }

  li .separate-title {
    margin-bottom: 22px;
    margin-top: 15px;
    color: $uf-r2021-dark-blue;
    font-size: 29px;
    letter-spacing: 2.15px;
    font-family: gentona, Arial, sans-serif;
    font-weight: 700;
  }
}

.how-to-apply-section {
  width: 100%;
  padding-top: 0;
  font-family: gentona, Arial, sans-serif;

  @media screen and (max-width: 740px) {
    margin-right: 0;
    padding: 0 2em;
    width: 100vw;
  }
}

.how-to-apply-section-center {
  width: 100%;
  padding-top: 0;
  font-family: gentona, Arial, sans-serif;

  a {
    color: #337ab7;
    text-decoration: underline;
    word-break: break-word;
  }

  @media screen and (max-width: 740px) {
    width: 100%;
    margin-right: 0;
    padding-top: 0;
  }
}

.how-to-apply-section-center2 {
  width: 100%;
  padding-top: 0;
  font-family: gentona, Arial, sans-serif;

  a {
    word-break: break-word;
  }

  @media screen and (max-width: 740px) {
    width: 100%;
    margin-right: 0;
    padding-top: 0;
  }
}

.two-button-group {
  padding: 20px 0 20px 0;
  margin-bottom: 20px;
  padding-bottom: 0px;

  @media screen and (max-width: 740px) {
    display: flex;
  }

  a {
    @media screen and (max-width: 740px) {
      display: flex;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .button-dates-download {
    background: var(--uf-blue) 0% 0% no-repeat padding-box;
    background: #002c75 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    width: 200px;
    color: white;
    font-family: "gentona-bold", Arial, sans-serif;
    text-align: center;
    letter-spacing: 1.7px;
    text-transform: uppercase;
  }

  .bombin {
    margin-bottom: -1px;
  }

  .button-freshman {
    width: 15em;
    color: #fff;
    background: #084b81 0 0 no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    border: none;
    font-size: 16px;
    margin-right: 10px;
  }
}

.hr-freshman {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #fa4616;
  margin: 30px 0;
  padding: 0;
}

// Header 2021
.heading-container {
  padding-top: 0;
  position: relative;

  @media screen and (min-width: 1346px) {
    padding-top: 51px;
  }

  @media screen and (max-width: 740px) {
    position: relative;
    padding-top: 0px;
  }
}

.header-cont {
  h1 {
    color: white;
  }

  @media screen and (max-width: 740px) {
    padding-left: 0;
    height: 60px;
  }

  @media screen and (max-width: 320px) {
    padding-top: 13vh;
    padding-left: 0;
    -ms-flex-pack: center;
    justify-content: center;
    height: 18vh;
  }

  .heading-cont {
    display: flex;
    align-items: center;
    // background-image: linear-gradient(90deg, #06307a, $uf-r2021-teal);
    display: flex;
    // padding-left: 8vw;
    padding: 1em 0 1em 8vw;
    position: fixed;
    width: 100vw;
    z-index: 3;
    position: absolute;
    background-color: #f8f9fa;
    color: #07317b;

    img {
      margin-left: auto;
      max-width: 100vw;

      @media screen and (max-width: 740px) {
        display: none;
        max-width: 100vw;
      }
    }

    @media screen and (max-width: 740px) {
      display: flex;
      font-size: 6px;
      height: 60px;
      top: 0;
      padding-left: 0;
      justify-content: center;
    }

    .heading-cont-span-1 {
      font-size: 2.5em;
      // color: #fff;
      color: #07317b;
      padding-right: 18px;
      letter-spacing: 2.15px;
      white-space: nowrap;
      text-transform: uppercase;

      @media screen and (max-width: 740px) {
        padding-top: 0;
      }
    }

    .heading-cont-span-2-border {
      border: 5px solid #fa4616;

      @media screen and (max-width: 740px) {
        border: none;
        margin-top: 0;
      }
    }

    .heading-cont-span-2 {
      font-size: 3em;
      margin-top: 0;
      // color: #fff;
      color: #07317b;
      font-family: gentona-bold, Arial, sans-serif;
      letter-spacing: 2.55px;
      text-transform: uppercase;
      margin-top: 40px;
      padding: 0px 20px 0 20px;
      white-space: nowrap;

      @media screen and (max-width: 740px) {
        font-size: 3em;
        // color: #fff;
        border: 5px solid #fa4616;
        font-family: gentona-bold, Arial, sans-serif;
        letter-spacing: 2.55px;
        font-weight: 650;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
  }
}

// Header DI 2021
.heading-container-di {
  padding-top: 51px;
  position: relative;

  @media screen and (max-width: 740px) {
    position: relative;
    padding-top: 0px;
  }
}

.header-cont-di {
  h1 {
    color: white;
  }

  @media screen and (max-width: 740px) {
    padding-left: 0;
    height: 60px;
  }

  @media screen and (max-width: 320px) {
    padding-top: 13vh;
    padding-left: 0;
    -ms-flex-pack: center;
    justify-content: center;
    height: 18vh;
  }

  .heading-cont-di {
    display: flex;
    align-items: center;
    background-image: linear-gradient(90deg, #06307a, $uf-r2021-teal);
    display: flex;
    padding-left: 8vw;
    position: fixed;
    width: 100vw;
    z-index: 3;
    position: absolute;

    img {
      margin-left: auto;
      max-width: 100vw;

      @media screen and (max-width: 740px) {
        display: none;
      }
    }

    @media screen and (max-width: 740px) {
      display: flex;
      font-size: 4px;
      height: 50px;
      top: 0;
      padding-left: 0;
      justify-content: center;
    }

    .heading-cont-span-1-di {
      font-size: 2.5em;
      // color: #fff;
      color: #07317b;
      padding-right: 18px;
      letter-spacing: 2.15px;
      white-space: nowrap;
      text-transform: uppercase;

      @media screen and (max-width: 740px) {
        padding-top: 0;
      }
    }

    .heading-cont-span-2-border-di {
      border: 5px solid #fa4616;

      @media screen and (max-width: 740px) {
        border: none;
        margin-top: 0;
      }
    }

    .heading-cont-span-2-di {
      font-size: 3em;
      margin-top: 0;
      // color: #fff;
      color: #07317b;
      font-family: gentona-bold, Arial, sans-serif;
      letter-spacing: 2.55px;
      text-transform: uppercase;
      margin-top: 40px;
      padding: 0px 20px 0 20px;
      white-space: nowrap;

      @media screen and (max-width: 740px) {
        font-size: 3em;
        // color: #fff;
        color: #07317b;
        border: 5px solid #fa4616;
        font-family: gentona-bold, Arial, sans-serif;
        letter-spacing: 2.55px;
        font-weight: 650;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
  }
}

.breadcrumb-2021 {
  @media screen and (max-width: 740px) {
    display: none;
  }

  @media screen and (max-width: 375px) {
    display: none;
  }

  display: -ms-flexbox;
  display: flex;
  padding-top: 5em;
  color: #0e0e0e;
  border-top-right-radius: 4px;
  font-size: 1.3em;
  font-family: gentona-book,
  Arial,
  sans-serif;
  z-index: 1;
  background-color: #fff;
  -ms-flex-pack: center;
  justify-content: flex-start;
  margin-bottom: 0;
  padding-left: 8vw;

  ul {
    padding: 0;
    margin: 0;
    background-color: #fff;
  }

  li+li {
    &:before {
      content: "\00a0>\00a0";
      position: relative;
      top: -1px;
      color: #505050;
      background-color: #fff;
    }
  }

  li {
    list-style-type: none;
    display: inline-block;
    padding: 0.25em 0;
    background-color: #fff;

    a {
      color: #002c75;
      transition: 0.2s ease-in-out;

      &:hover {
        color: #000000;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

// Footer
.footer {
  background-color: #07317b;
  color: white;
  margin-top: 2em;
  z-index: 5;
  position: relative;

  p {
    background-color: #07317b;
    color: white;
  }

  a {
    background-color: #07317b;
    color: white;
  }

  a:hover {
    color: $uf-r2021-teal;
    transition: all 0.35s;
  }

  a:focus {
    color: $uf-r2021-teal;
    transition: all 0.35s;
  }

  h2 {
    background-color: #07317b;
    color: $uf-r2021-teal;
  }

  @media screen and (max-width: 740px) {
    margin-left: 0;
  }
}

.footer .social {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.footer .social .btn-social {
  display: inline-block;
  height: 3em;
  width: 3em;
  line-height: 3.3em;
  margin: 12px;
  box-sizing: content-box;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #ffffff;
  color: #07317b;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 40px;
  vertical-align: 0;
}

.footer .social .btn-social:focus,
.footer .social .btn-social:hover {
  background-color: #07317b;
  color: #ffffff;
}

.footer .address {
  font-family: gentona, Arial, sans-serif;
  font-size: 0.9em;
}

// Dates and Deadlines
.date-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.left-date-box {
  background: $uf-r2021-dark-blue 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  width: 97px;
  height: 97px;
  margin-right: 2vw;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  min-height: 97px;
  min-width: 97px;

  p {
    color: white;
    font: normal normal 600 15px/28px Gentona;
    letter-spacing: 0.75px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    margin: 0 auto;
    padding: 0;
  }

  .top-date {
    font: normal normal 600 15px/28px Gentona;
    letter-spacing: 0.75px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    height: 15px;
  }

  .middle-date {
    text-align: left;
    font: normal normal 600 33px/54px Gentona;
    letter-spacing: 1.65px;
    color: #ffffff;
    opacity: 1;
    height: 30px;
  }

  .bottom-date {
    font: normal normal 600 33px/54px Gentona;
    letter-spacing: 1.65px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }
}

.right-date-information {
  padding-left: min(2em, 2vw);

  .right-date-heading {
    font: normal normal 600 31px/51px Gentona;
    letter-spacing: 0px;
    color: #231f20;
    opacity: 1;
    margin-bottom: 0;
    // margin-top: 5px;
    margin-top: 0;
    padding: 0;

    @media screen and (max-width: 740px) {
      margin-top: 3px;
      margin-left: 10px;
      margin-top: 0px;
    }
  }

  .date-information-center-text {
    font: normal normal normal 22px/51px Gentona;
    letter-spacing: 0px;
    color: #002c75;
    opacity: 1;
    margin: 0;

    @media screen and (max-width: 740px) {
      margin-top: 3px;
      margin-left: 10px;
    }
  }

  .date-information-lower-text {
    font: normal normal normal 15px/24px Gentona;
    letter-spacing: 0.75px;
    color: #231f20;
    opacity: 1;
    margin-bottom: 2vh;
    margin-top: 1vh;
    font-size: 18px;

    @media screen and (max-width: 740px) {
      margin-top: 3px;
      margin-left: 10px;
    }
  }
}

.print-section {
  h1 {
    text-align: left;
    font: normal normal bold 17px/26px Gentona;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    opacity: 1;
    margin: 10px 0 30px 0;
  }
}

.down-arrow {
  margin-left: 1.7vw;

  @media screen and (max-width: 740px) {
    margin-left: 25vw;
  }

  .down-arrow-top {
    width: 121px;
    height: 82px;
    background: #65bac8 0% 0% no-repeat padding-box;
    opacity: 1;

    @media screen and (max-width: 740px) {
      margin-left: -15vw;
    }
  }

  .down-arrow-bottom {
    width: 0px;
    height: 0px;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-top: 100px solid #65bac8;
    opacity: 1;
    margin-left: -1.5vw;

    @media screen and (max-width: 740px) {
      margin-left: -25vw;
    }
  }
}

// navbar
.container-fluid>.navbar-header {
  @media screen and (max-width: 740px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-default {
  @media screen and (max-width: 740px) {
    background-color: #00529b;
  }

  background-color: white;
  border: 0;
  font-family: gentona,
  Arial,
  sans-serif;
  transition: all 0.35s;
  margin-bottom: 0;
}

.navbar-default .navbar-toggle:hover {
  @media screen and (max-width: 740px) {
    background-color: #f36812;
  }
}

.navbar-default .navbar-toggle {
  @media screen and (max-width: 740px) {
    background-color: #f36812;
  }

  background-color: #dd661e;
  border-radius: 0;
  margin: 0;
  border: 0;
  padding: 18px;
  height: 65px;
  width: 65px;
}

.navbar-default .nav>li {
  @media screen and (max-width: 740px) {
    padding-left: 3vw;
    padding-top: 1vh;
  }

  background-color: white;
  border: none;
  letter-spacing: 1.5px;
  padding: 0;
}

.navbar-default .nav>li:hover {
  @media screen and (max-width: 740px) {
    padding-left: 3vw;
    padding-top: 1vh;
  }

  color: white;
  height: 66px;
}

.navbar-default .nav>li:active {
  background-color: white;
}

.navbar-default .nav>li>ul.dropdown-menu {
  @media screen and (max-width: 740px) {
    border: 0;
    background-color: #fff;
    padding-left: 3vw;
    position: absolute;
    left: 0;
    font-family: gentona, Arial, sans-serif;
    font-weight: 700;
    padding-right: 0;
    min-width: 100%;
  }

  top: 65px;
  border: 0;
  background-color: #fff;
  padding-left: 0px;
  position: absolute;
  left: 0;
  min-width: 20em;
  font-family: gentona,
  Arial,
  sans-serif;
  font-weight: 700;
  padding-right: 0;
}

.navbar-default .navbar-header .navbar-brand {
  @media screen and (max-width: 740px) {
    color: White;
  }

  color: #00529b;
  padding: 24px 0 0 15px;
  font-family: gentona-bold,
  Arial,
  sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.3px;
}

.admissions-2021-nav-logo {
  font-size: 2.25em;
  font-family: gentona-bold, Arial, sans-serif;
}

.navbar-default .logo {
  background-color: #f36812;
}

.dropdown-toggle img {
  height: 30px;
  width: 30px;
}

.navbar-default .nav>li:nth-child(2n) {
  background-color: #ffff;
}

.navbar-default .nav>li:nth-child(2n):hover {
  color: white;
  border-bottom: none;
  height: 66px;
}

.navbar-default .nav>li>a:focus:hover,
.navbar-default .nav>li>a:hover {
  color: white;
  background-color: #002c75;
  border-bottom: none;
  height: 66px;
  stroke: white;
}

.navbar-default .navbar-nav>.open>a {
  background-color: white;
  color: black;
  height: 66px;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus {
  background-color: #002c75;
  color: white;
  transform: none;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus .feather-chevrons-down {
  background-color: #002c75;
  color: white;
  stroke: white;
}

.navbar-default .navbar-nav>.open>a:focus .feather-chevrons-down {
  transform: rotate(180deg);
  transform-origin: 50% 40%;
}

.navbar-default .navbar-nav>.open>a:not(:focus) .feather-chevrons-down {
  transform: rotate(180deg);
  transform-origin: 50% 40%;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  background-color: #002c75;
  color: white;
}

.navbar-default .nav>li>a:hover .feather-chevrons-down {
  color: #fff;
  background-color: #002c75;
  stroke: white;
}

.nav>li.search-icon>a:hover {
  padding-bottom: 0;
  background-color: #f36812;
}

.nav>li.search-icon>a:focus {
  padding-bottom: 0;
  background-color: #f36812;
}

.nav>li.search-icon>a {
  padding-bottom: 0;
  height: 66px;
}

.navbar-default .nav>li>ul.search-dropdown {
  right: 0;
  left: auto;
  width: 25vw;
}

.dropdown-inline {
  display: flex;
}

.drowdown-inline-link-name {
  margin-top: 9px;
}

.nav-inline-link-name {
  margin-top: 9px;
}

.nav-inline-link-name:hover {
  color: #fff;
  height: 66px;
  padding: 0;
  margin: 0;
}

.nav-inline-link-name a:hover {
  color: #fff;
  background-color: #002c75;
  border-bottom: none;
  height: 66px;
  stroke: #fff;
  padding-top: 24px;
}

.feather-chevrons-down {
  margin-top: 11px;
  margin-left: 4px;
}

.dropdown-toggle {
  padding-bottom: 0;
}

.baw {
  @media screen and (max-width: 488px) {
    width: auto !important;
  }
}

.down-arrow-svg {
  transform: rotate(270deg);
}

.after-drop {
  font-size: 1.2em;

  @media screen and (max-width: 740px) {
    color: White;
  }
}

.after-drop a {
  @media screen and (max-width: 740px) {
    padding-left: 10vw;
  }

  padding-left: 15px;
}

.search-icon {
  fill: white;
  color: white;
}

.navbar-default .nav>li.search-icon {
  background-color: #002c75;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  padding: 0;
  height: 66px;
  padding-right: 15px;
}

.navbar .navbar-default .navbar-fixed-top {
  margin-bottom: 0;
}

.navbar-default .nav {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

@media (min-width: 1026px) {
  .navbar-default .nav>li.search-icon:hover {
    background-color: #b05118;
    display: flex;
    align-items: center;
  }
}

.container-fluid {
  padding: 0;
}

// international
.increase-heading {
  font-size: 3em;
}

// general
.inline {
  display: inline-block;
  vertical-align: top;
}

.fw-900 {
  font-weight: 900;
}

.spacing-tb {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gray {
  color: #8b8b8b;
}

.hr-thick {
  border-top: 10px solid $uf-r2021-light-gray;
}

.scrollable-menu {
  height: auto;
  overflow-x: hidden;
  max-height: 50vh;
}

// back to top button
.top-link {
  transition: all 0.25s ease-in-out;
  position: fixed;
  bottom: 180px;
  right: 0;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 3em 3em 0;
  border-radius: 50%;
  padding: 2em;
  width: 80px;
  content: "Back To Top";
  text-decoration: none;
  outline: none;
  z-index: 2000;

  @media screen and (max-width: 740px) {
    margin: 0 -1.3em 0 0;
    width: 80px;
    height: 80px;
    bottom: 7em;
  }

  @media screen and (max-width: 499px) {
    margin: 0 -11px 0 0;
    width: 80px;
    height: 80px;
    bottom: 6em;
  }
}

.top-link:focus {
  text-decoration: none;
  outline: none;
}

.specialli {
  li {
    font-family: gentona, Arial, sans-serif;
    font-size: 18px;
    padding: 3px;
  }

  .indent2 {
    font-family: gentona, Arial, sans-serif;
    font-size: 18px;
    padding: 3px;
  }
}

.top-link:active {
  text-decoration: none;
  outline: none;
}

.top-link svg {
  fill: #fa4616;
  color: white;
  width: 74px;
  height: 62px;
  opacity: 1;

  @media screen and (max-width: 740px) {
    width: 34px;
    height: 42px;
    fill: $uf-r2021-dark-blue;
    color: white;
  }
}

.top-link svg:hover {
  opacity: 0.5;
}

.screen-reader-text {
  position: absolute;
  clip-path: inset(50%);
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);

  &:focus {
    display: block;
    top: 5px;
    left: 5px;
    z-index: 100000;
    clip-path: none;
    background-color: #eee;
    padding: 15px 23px 14px;
    width: auto;
    height: auto;
    text-decoration: none;
    line-height: normal;
    color: #444;
    font-size: 1em;
    clip: auto !important;
  }
}

// sidenav
.dropdown-toggle .side-nav {
  padding-left: 0;
}

.collapse.navbar-collapse.side-nav {
  padding: 0;
  margin: 0;

  li:first-child a {
    color: #002c75;
  }

  li a {
    padding: 0;
    margin: 0;
  }
}

.hr-freshman-side {
  display: block;
  border: 0;
  border-top: 1px solid grey;
  margin: 0.8em 0;
  padding: 0;
}

.hr-freshman-side-pace {
  display: block;
  border: 0;
  border-top: 1px solid grey;
  margin: 0em 0;
  padding: 0;
}

.after-drop-side-nav {
  font-size: 0.9em;
}

//slider
* {
  box-sizing: border-box;
}

.mySlides {
  display: none;
}

img {
  vertical-align: middle;
}

.buttons {
  text-align: center;
  margin-top: 10px;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.nomarginbot {
  margin-bottom: 37px !important;
}

/* The dots/bullets/indicators */
.dot {
  border: 3px solid white;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 0 2px;
  background-color: #002c75;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

span.dot.active,
.dot:hover {
  background-color: #fa4616;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {

  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

.span.dot.active:after {
  content: none;
}

//Pace Collabsabel
.pacecoll {
  padding: 0px;
  background-color: #fff;
  background: #fff;
  font-size: 19px;
}

.pacea {
  color: #002c75;
  font-size: 1.3em;
  letter-spacing: 2.15px;
  font-family: gentona, Arial, sans-serif;
  font-weight: 700;
}

.svgdown {
  margin-bottom: -14px;
  background-color: #fff;
}

.svgout {
  margin-bottom: -2px;
  margin-left: 7px;
}

.contentlefts {
  border-left: 2px solid #002c75;
  padding-left: 7px;
}

a.collapsible.pacea.active {
  background-color: #fff;
}

a.collapsible.pacecoll.active:hover {
  background-color: transparent;
}

.collapsible.pacecoll {
  color: #002c75;
  background-color: transparent;
  font-size: 19px;
  letter-spacing: 2.15px;
  font-family: "gentona", Arial, sans-serif;
  font-weight: 700;
  position: relative;
  text-decoration: none;
}

// .collapsible.pacecoll:hover {
//   color: #002c75;
//   background-color: transparent;
// }
.padtop33 {
  padding-top: 20px;
}

.collapsible.pacecoll::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #002c75;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.collapsible.pacecoll:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.bingbong a.collapsible.pacecoll:after {
  content: "";
}

.bingbong a.collapsible.pacecoll:hover {
  background-color: transparent;
}

// .pace-sidenav-collapse:hover:after {
//   color: #002c75;
//   background-color: transparent;
// }

// .pace-sidenav-collapse.active:focus:after {
//   content: "\2212";
// }

// navbar clear
.clear-navigation {
  background-color: transparent;
  font-family: gentona-bold, Arial, sans-serif;
  font-size: 1.3em;
}

ul {
  margin: 0;
  padding: 0;
}

.menu {
  display: flex;
  flex-direction: column;

  li {
    display: block;
    list-style-type: none;
    text-transform: uppercase;
  }

  li a {
    display: flex;
    text-align: center;
    text-decoration: none;
    color: black;
    padding: 1rem;
  }

  li a:hover {
    background: #002c75;
    color: #fff;

    .prev,
    .next,
    .text {
      font-size: 11px;
    }
  }
}

.span.dot.active:after {
  content: none;
}

@media (min-width: 800px) {
  .menu {
    flex-direction: row;
    justify-content: space-between;
  }

  nav ul ul {
    display: none;
    position: absolute;
  }

  nav ul li:hover>ul {
    padding-left: 0;
    background: white;
  }

  nav ul li:hover>ul a {
    color: #002c75;
  }

  .sub-menu {
    li {
      display: flex;
      padding-right: 2em;
    }
  }

  nav ul ul ul {
    margin: 0 0 0 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  }
}

p {
  font-family: gentona, Arial, sans-serif;
  line-height: 1.5;
  font-size: 1.25em;
  color: rgb(0, 0, 0);
  line-height: 1.5;
  letter-spacing: 0.75px;
}

a {
  font-family: gentona, Arial, sans-serif;
  line-height: 1.5;
  word-break: break-word;
  letter-spacing: 0.75px;
}

.resourceslinks {
  display: block;
  font-size: 21px;
  color: #002c75;
  text-decoration: underline;
  padding: 5px;
  font-weight: 600;
  word-break: break-word;
}

h3 {
  font-size: 22px;
  font-family: gentona-book, Arial, sans-serif;
  color: $uf-r2021-teal;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 2px;
}

h1 {
  font-size: 40px;
  font-family: gentona, Arial, sans-serif;
  color: #000;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  padding-top: 9px;
}

h2 {
  font-family: gentona, Arial, sans-serif;
  color: #fa4616;
  line-height: 1.5;
  font-weight: 900;
  letter-spacing: 0.85;
  line-height: 1.2;
  font-size: 21px;
}

.side-nav-container-line {
  border-left: 5px solid #fa4616;
  padding-left: 25px;
  max-width: 320px;
}

.side-nav-container-line-di {
  border-left: 5px solid #fa4616;
  padding-left: 25px;
  width: 350px;
}

.blue {
  color: #002c75;
}

.side-link-container li .separate-title.bottomtitle {
  color: #000;
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 10px;
}

.bottomtitle {
  color: #000;
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 10px;
}

.indentcontainer {
  margin-left: 5vw;
}

.grey {
  background-color: #e4e4e4;
  padding: 21px;
}

.darkgrey {
  color: #002c75;
  font-weight: bolder;
  letter-spacing: 1.5px;
  margin: 14px;
}

.buttonwidth {
  width: 350px !important;
}

.dropdown {
  border-radius: 0px !important;
  margin: 0px;
  padding: 23px;
}

.svgdown.active {
  transform: rotate(180deg);
  transition: transform 1s linear;
}

.svgdown {
  transform: rotate(0deg);
  transition: transform 1s linear;
}

.applytoday {
  line-height: 1.5;
  font-weight: 100;
  color: #002c75;
  font-size: 3vw;
  padding-bottom: 0;
  margin-bottom: 1px;
  margin-top: 0;
}

.application {
  font-family: "gentona-bold";
  font-weight: 500;
  font-size: 8vw;
  margin-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  color: #fa4616;
}

.applycontainergrey {
  background-color: $uf-r2021-light-gray;
  width: 100vw;
  padding-bottom: 39px;

  h1 {
    font-size: 40px;
    color: #012d76;
    letter-spacing: 0;
    line-height: 1.2;
    padding-top: 9px;
    letter-spacing: 1.8px;
    padding-top: 50px;
    padding-bottom: 17px;
    border: none;
  }

  button {
    border-radius: 14px;
    font-size: 20px;
    border: none;
    background: #084b81;
    margin: 10px;
    padding: 16px 30px 15px;
    color: #fff;
    font-family: "gentona-bold";
    letter-spacing: 2.3px;
    border: 1px solid transparent;
  }

  button:hover {
    background: #fff;
    color: #084b81;
    border: 1px solid #084b81;
  }
}

.startswithan {
  font-size: 4vw;
  color: #00529b;
  letter-spacing: 0;
  line-height: 1.2;
  padding-top: 9px;
  padding-bottom: 0;
  padding-top: 300px;

  @media only screen and (max-width: 750px) {
    padding-top: 30px;
  }
}

.apply-home-heading {
  font-size: 5.9rem;
  color: #00529b;
  letter-spacing: 0;
  line-height: 1.2;
  padding-bottom: 0;
  padding-top: 25px;
  font-weight: 700;
}

.moneyjpg {
  position: absolute;
  width: 117px;
  margin-left: -136px;
  margin-top: 64px;
}

.fontweight22 {
  font-weight: 400;
}

.tutpad {
  padding-top: 121px;

  @media only screen and (max-width: 1025px) {
    padding-top: 0px;
  }
}

.greyspotify {
  background-color: $uf-r2021-light-gray;
  height: 210px;
  width: 100vw;
  padding-bottom: 25px;

  h1 {
    padding: none;
    text-align: left;
    letter-spacing: 1.6px;
    color: #002c75;
    opacity: 1;
    font-family: "gentona-bold";
    font-weight: 500;
    font-size: 38px;
    padding-bottom: 0px;
    margin-bottom: -2px;
    border: none;
    padding-top: 4px;
  }

  p {
    text-align: left;
    font: normal normal medium 15px/21px Gentona;
    letter-spacing: 0.75px;
    color: #000;
    opacity: 1;
    max-width: 516px;
    padding-left: 74px;
    margin-top: -1px;
  }
}

.centertext24 {
  @media screen and (max-width: 740px) {
    text-align: center;
  }
}

.margright2 {
  margin-right: 0px !important;
}

@media screen and (max-width: 1025px) {
  .greyspotify {
    height: fit-content;
  }

  .belizeimg {
    width: 0;
  }

  .belizetxt {
    padding-left: 50px;
  }

  .greyspotify p {
    padding-left: 54px;
  }

  .belizetxt h1 {
    padding-left: 48px;
  }

  .paraguay {
    padding-left: 55px;
  }
}

@media screen and (max-width: 570px) {
  .greyspotify {
    height: fit-content;
  }

  .belizeimg {
    width: 0;
  }

  .belizetxt {
    text-align: center !important;
    padding-left: 0;
  }

  .belizetxt p {
    text-align: center;
    padding-left: 0 !important;
  }

  .belizetxt h1 {
    padding-left: 0;
    text-align: center;
  }

  .paraguay {
    padding-left: 0px;
  }

  .greyspotify p {
    padding-left: 0px;
  }
}

@media screen and (max-width: 769px) {
  .paraguay {
    padding-left: 0px;
  }
}

.aftergainesvillecontainer {
  @media only screen and (max-width: 1025px) {
    height: 0px;
  }
}

.spler {
  float: right;

  @media only screen and (max-width: 1025px) {
    float: none;
    margin-left: 5vh;
  }
}

.studentprofile {
  margin-bottom: 40px;

  h2 {
    padding: 0;
    margin: 0;
    font-size: 28px;
    font-family: "gentona-bold";
    margin-left: 8px;
    color: #000;
    letter-spacing: 0;
    line-height: 1.2;
  }

  h3 {
    color: #002c75;
    line-height: 1.5;
    letter-spacing: 0.85;
    line-height: 1.2;
    font-size: 48px;
    margin: 0;
    padding: 0;
    font-family: "gentona-bold";
    margin-left: 21px;
    font-weight: 100;
  }
}

.pacemajors {
  border-radius: 0 !important;
  margin: 0;
  padding: 23px;
  font-size: 29px;
  border: none;
  padding-left: 0px;
  border-bottom: 2px solid #c7c7c7;
  border-radius: 0 !important;
  margin: 0;
  padding: 23px;
  font-size: 29px;

  padding-left: 0px;
}

.underline {
  text-decoration: underline;
  color: #000;
  font-size: 18px;
}

.no-underline {
  text-decoration: none;
}

.majorsections {
  p {
    font-size: 18px;
    margin: 13px;
  }

  strong {
    font-size: 18px;
  }

  a {
    font-size: 20px;
  }
}

// skip link
.skip-link-2021 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2000;
  background-color: #011c75;
  height: 10vh;
  color: white;
  border: none;
  border-bottom: 1px dotted #fff;
  font-weight: 700;
  font-size: 2rem;
  text-decoration: none;
  letter-spacing: 0.04em;
  left: 0;
  padding: 0.6em;
  position: absolute;
  transform: translateY(-102%);
  transition: transform 0.3s;
}

.kljdfjlk {
  background-color: $uf-r2021-light-gray;
  padding: 30px;
  margin-top: -157px;
  padding-top: 144px;
  margin-left: 39px;

  h1 {
    color: #002c75;
  }

  p {
    color: #002c75;
  }

  @media screen and (max-width: 740px) {
    margin-left: 0px;
  }
}

.includimg1 {
  max-width: 387px;
  margin: 63px 63px 37px;
  width: 100%;
}

.includimg2 {
  max-width: 587px;
  width: 100%;
}

.extrapadding {
  padding-top: 86px;

  @media screen and (max-width: 740px) {
    padding-top: 20px;
  }
}

.skip-link-2021:focus {
  transform: translateY(0%);
  color: #fff;
  border-bottom: 1px solid #fff;
}

.skip-link-2021:active {
  transform: translateY(0%);
  color: #fff;
  border-bottom: 1px solid #fff;
}

.skip-link-2021:visited {
  transform: translateY(0%);
  color: #fff;
  border-bottom: 1px solid #fff;
}

.svgbutton {
  margin-bottom: -15px;
  color: #002c75;
}

.grey-box {
  margin: 7em;
  border: 10px solid #fff;
  width: 15em;
}

@media only screen and (max-width: 750px) {
  .guidetoapplyingcontainer {
    height: auto;
    margin-bottom: 0px;
  }

  .grey-box {
    margin: auto;
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .smallmargin {
    margin: 14px;
  }

  .includimg1 {
    max-width: 387px;
    margin: 0px;
    width: 100%;
    margin-bottom: 40px;
  }
}

.no-display-mobile {
  @media only screen and (max-width: 1025px) {
    display: none;
  }
}

// responsive table
.general-rsp-table {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    background-color: #011c75;
    color: white;
    font-weight: bold;
    border: 0.125em solid $uf-r2021-light-gray;
    line-height: 1.5;
    padding: 0.75em;
    text-align: left;
  }

  td {
    border: 0.125em solid #333;
    line-height: 1.5;
    padding: 0.75em;
    text-align: left;
  }

  @media (max-width: 30em) {
    th {
      background-color: #011c75;
      color: white;
    }

    thead tr {
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tr {
      border: 0.125em solid #333;
      border-bottom: 0;
    }

    tr+tr {
      margin-top: 1.5em;
    }

    tr,
    td {
      display: block;
    }

    td {
      border: none;
      border-bottom: 0.125em solid #333;
    }

    td:first-child {
      background-color: #011c75;
      color: white;
      font-weight: bold;
    }

    td:before {
      content: attr(data-label);
      display: inline-block;
      font-weight: bold;
      line-height: 1.5;
      // margin-left: -100%;
      width: 100%;
    }
  }

  @media (max-width: 20em) {
    td {
      padding-left: 0.75em;
    }

    td:before {
      // display: block;
      margin-bottom: 0.75em;
      margin-left: 0;
    }
  }
}

#southern-scholarships,
#currently-enrolled-scholarships,
#transfer-scholarships,
#international-scholarships,
#graduate-scholarships,
#military-scholarships,
#faq-scholarships {
  display: none;

  @media screen and (max-width: 740px) {
    display: block;
  }
}

#freshman-scholarships {
  display: block;
}

#dependent-independent,
#exemptions,
#fee-waiver {
  display: none;

  @media screen and (max-width: 740px) {
    display: block;
  }
}

#establishing-residency {
  display: block;
}

ul.breadcrumb>li {
  font-size: 0.9em;
  font-weight: 700;
  font-family: "gentona", Arial, sans-serif;
}

ul.breadcrumb>li>a:hover {
  text-decoration: none;
}

.side-link-container-button {
  color: #002c75;
  font-size: 19px;
  font-family: gentona, Arial, sans-serif;
  font-weight: 700;
  position: relative;
  text-decoration: none;
  background: transparent;
  outline: none;
  border: none;
  margin-bottom: 0px;
  padding: 0px;
}

.side-link-container-button-mobile {
  color: white;
  font-size: 19px;
  font-family: gentona, Arial, sans-serif;
  font-weight: 700;
  position: relative;
  text-decoration: none;
  background: transparent;
  outline: none;
  border: none;
  margin-bottom: 0px;
  padding: 0px;
}

@media screen and (max-width: 740px) {
  .side-link-container-button-mobile {
    color: #fff;
    text-decoration: none;
    // border: 2px solid #07317b;
    border-radius: 15px;
    padding: 0.2em 0.4em;
    background-color: #07317b;
    font-size: 12.25px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #002c75;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#page-address {
  font-size: 1.5em;
  color: #00386c;
  font-weight: bold;
}

#page-address-2 {
  font-size: 1.5em;
  color: #00386c;
  font-weight: bold;
}

.circular-photo-container {
  width: 70%;
  margin: 0 auto;
}

.circular-image-president {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Lindsey_Adams.JPG");
}

.circular-image-vice-president {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Ana_Sanchez.JPG");
}

.circular-image-treasurer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Brittnie_Pang.JPG");
}

.circular-image-secretary {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Clara_Calavia_Sarnago.JPG");
}

.circular-image-recruitment-director {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Annie_Stockham.JPG");
}

.circular-image-membership-director {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Kathleen_Cintado.JPG");
}

.circular-image-public-relations-director {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Vanessa_Toussaint.JPG");
}

.circular-image-special-programs-director {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Isabella_Touma.JPG");
}

.circular-image-special-programs-deputy {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Ava_Bixby.JPG");
}

.circular-image-tour-director {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Brianna_Riddle.JPG");
}

.circular-image-tour-deputy {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/2021-2022/Teja_Thota.JPG");
}

.sao-name-title {
  text-align: center;
}

.sao-logo {
  width: 350px;

  @media screen and (max-width: 1300px) {
    width: 25vw;
  }
}

.top-first-circular-photo-container {
  width: 30%;
  margin-top: 50px;

  @media screen and (max-width: 740px) {
    margin-top: 0px;
  }
}

.top-first-circular-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/Picture8.png");
}

.top-second-circular-photo-container {
  width: 20%;
  margin-left: -70px;

  @media screen and (max-width: 740px) {
    margin-left: 0px;
  }
}

.top-second-circular-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 100%;
  width: 120%;
  padding-top: 100%;
  border: 15px solid #b2dce3;
  background-image: url("/img/sao-photos/Picture6.jpg");

  @media screen and (max-width: 1030px) {
    margin-top: 45px;
    margin-left: 100px;
  }

  @media screen and (max-width: 740px) {
    border: 5px solid #b2dce3;
    margin-top: 0px;
    margin-left: 0px;
  }
}

.top-third-circular-photo-container {
  width: 40%;
  margin-top: 120px;
  margin-left: 150px;

  @media screen and (max-width: 740px) {
    margin-top: 0px;
    margin-left: 0px;
  }
}

.top-third-circular-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-image: url("/img/sao-photos/Picture3.jpg");

  @media screen and (max-width: 1030px) {
    margin-bottom: 35px;
  }

  @media screen and (max-width: 740px) {
    margin-left: 10px;
    margin-bottom: 40px;
  }
}

.sao-top-photo-section {
  display: flex;
  margin-top: 0;

  @media screen and (max-width: 740px) {
    margin-top: 2em;
  }
}

.column-container-right ul {
  list-style: none;
}

.column-container-right ul li {
  color: #0e0e0e;
  font-size: 18px;
  margin-bottom: 12px;
  display: -ms-flexbox;
  -ms-flex-align: center;
  font-family: "gentona";
  padding-top: -1px;
  margin-top: 22px;
  padding-left: 18px;
  margin-left: 18px;
  line-height: 1.5;
  letter-spacing: 0.75px;
}

.column-container-right ul li::before {
  content: " \25A0";
  color: #fa4616;
  display: inline-block;
  margin-right: 10px;
  font-size: 30px;
  position: absolute;
  margin-left: -38px;
  margin-top: -10px;
}

.column-container-right ul li::after {
  margin-bottom: 2em;
}

.column-container-right ul.no-bullet li::before {
  content: "";
  color: #fa4616;
  display: none;
  margin-right: 10px;
  font-size: 30px;
  position: absolute;
  margin-left: -38px;
  margin-top: -10px;
}

.column-container-right ul.no-bullet li::after {
  margin-bottom: 2em;
}

.ns-li-bullet {
  content: " \25A0";
  color: #fa4616;
  display: inline-block;
  margin-right: 10px;
  font-size: 30px;
  position: absolute;
  margin-left: -38px;
  margin-top: -10px;
}

.sao-name-title p {
  font-family: gentona, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.75px;
  margin-top: 0px;
  margin-bottom: 2em;
}

.sao-name-title h2 {
  color: #00386c;
  font-weight: 900;
  line-height: 1.2;
  font-size: 2em;
  margin-bottom: 5px;
}

.right-date-information .date-information-lower-text {
  font: normal normal normal 15px/24px Gentona;
  letter-spacing: 0.75px;
  color: #231f20;
  opacity: 1;
  margin-bottom: 2vh;
  margin-top: 0;
  font-size: 18px;
}

// general tags

h1 {
  color: #000;
  letter-spacing: 0;
  line-height: 1.2;
  padding-top: 9px;
  font-size: 45px;
  border-bottom: 3px solid #f37021;
  padding-bottom: 19px;
  letter-spacing: 1.75px;
  font-family: "gentona-bold", Arial, sans-serif;

  @media screen and (max-width: 740px) {
    letter-spacing: 0;
  }
}

h2 {
  font-size: 31px;
  color: #000;
  letter-spacing: 0;
  line-height: 1.2;
  padding-bottom: 8px;
  padding-top: 4px;

  @media screen and (max-width: 740px) {
    letter-spacing: 0;
  }
}

h3 {
  color: #002c75;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 1.2;
  font-size: 25px;
  font-family: 'gentona-bold';

  @media screen and (max-width: 740px) {
    letter-spacing: 0;
  }
}

h4 {
  font-size: 20px;
  font-family: gentona-book, Arial, sans-serif;
  color: #002c75;
  letter-spacing: 2px;
  margin-bottom: 2px;

  @media screen and (max-width: 740px) {
    letter-spacing: 0;
  }
}

h5 {
  font-family: gentona-book, Arial, sans-serif;
  letter-spacing: 2px;
  font-size: 16px;
  color: #000;

  @media screen and (max-width: 740px) {
    letter-spacing: 0;
  }
}

a {
  color: #6A2A60;
  text-decoration: underline;
  word-break: break-word;
}

// buttons

.button-link-teal {
  color: $uf-r2021-dark-blue;
  background-color: $uf-r2021-teal;
  text-decoration: none;
  font-weight: 700;
  opacity: 1;
  margin: 0 10px 10px 0;
  border-radius: 14px;
  font-size: 16px;
  outline: none;
  font-family: gentona-book;
  -webkit-appearance: button-bevel;
  padding: 15px 25px;

  @media screen and (max-width: 812px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.button-link-teal:hover {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
}

.button-link-teal:active {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
}

.button-link-teal:focus {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
}

.button-link-white {
  color: #07317b;
  background-color: #fff;
  text-decoration: none;
  font-weight: 700;
  opacity: 1;
  margin: 0 10px 10px 0;
  border-radius: 14px;
  font-size: 16px;
  outline: none;
  font-family: gentona-book;
  -webkit-appearance: button-bevel;
  padding: 15px 25px;

  @media screen and (max-width: 812px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.button-link-white:hover {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: white;
}

.button-link-white:active {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: white;
}

.button-link-white:focus {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: white;
}

.button-link-dark-blue {
  color: #fff;
  background-color: #07317b;
  text-decoration: none;
  font-weight: 700;
  opacity: 1;
  margin: 0 10px 10px 0;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  font-family: gentona-book;
  -webkit-appearance: button-bevel;
  padding: 15px 25px;

  @media screen and (max-width: 812px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.button-link-dark-blue:hover {
  text-decoration: none;
  outline: none;
  background-color: white;
  color: $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-link-dark-blue:active {
  text-decoration: none;
  outline: none;
  background-color: white;
  color: $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-link-dark-blue:focus {
  text-decoration: none;
  outline: none;
  background-color: white;
  color: $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-link-dark-blue2 {
  color: #fff;
  background-color: #07317b;
  text-decoration: none;
  font-weight: 700;
  opacity: 1;
  margin: 0 10px 10px 0;
  border-radius: 14px;
  font-size: 16px;
  outline: none;
  font-family: gentona-book;
  width: 299px;
  padding: 15px 65px;
  text-align: center;

  @media screen and (max-width: 812px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.button-link-dark-blue2:hover {
  text-decoration: none;
  outline: none;
  background-color: white;
  color: $uf-r2021-dark-blue;
}

.button-link-dark-blue2:active {
  text-decoration: none;
  outline: none;
  background-color: white;
  color: $uf-r2021-dark-blue;
}

.button-link-dark-blue2:focus {
  text-decoration: none;
  outline: none;
  background-color: white;
  color: $uf-r2021-dark-blue;
}

.button-link-light-teal {
  color: $uf-r2021-dark-blue;
  background-color: $uf-r2021-teal;
  text-decoration: none;
  font-weight: 700;
  opacity: 1;
  margin: 0 10px 10px 0;
  border-radius: 14px;
  font-size: 16px;
  outline: none;
  font-family: gentona-book;
  -webkit-appearance: button-bevel;
  padding: 15px 15px;

  @media screen and (max-width: 812px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.button-link-light-teal:hover {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
  -webkit-appearance: none;
}

.button-link-light-teal:active {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
  -webkit-appearance: none;
}

.button-link-light-teal:focus {
  text-decoration: none;
  outline: none;
  background-color: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
  -webkit-appearance: none;
}

.button-border-white {
  border: 1px solid transparent;
  -webkit-appearance: none;
}

.button-border-white:hover {
  border: 1px solid white;
  -webkit-appearance: none;
}

.button-border-white:active {
  text-decoration: none;
  outline: none;
  border: 1px solid white;
  -webkit-appearance: none;
}

.button-border-white:focus {
  text-decoration: none;
  outline: none;
  border: 1px solid white;
  -webkit-appearance: none;
}

.button-border-dark-blue {
  border: 1px solid transparent;
  -webkit-appearance: none;
}

.button-border-dark-blue:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-border-dark-blue:active {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-border-dark-blue:focus {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-border-dark-blue-permanent {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-border-dark-blue-permanent:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-border-dark-blue-permanent:active {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.button-border-dark-blue-permanent:focus {
  text-decoration: none;
  outline: none;
  border: 1px solid $uf-r2021-dark-blue;
  -webkit-appearance: none;
}

.td-none {
  text-decoration: none;
}

.r-mr-3 {
  margin-right: 3em;

  @media screen and (max-width: 740px) {
    margin-right: 0;
  }
}

.r-pb-mb {
  padding-bottom: 0;
  margin-bottom: 10px;
}

.r-mt-0 {
  margin-top: 0;
}

.r-pb-25p {
  padding-bottom: 25px;
}

.r-mb-2 {
  margin-bottom: 2em;
}

.r-mb-3 {
  margin-bottom: 3em;
}

.r-mb-99 {
  margin-bottom: 4em;
  // @media screen and (max-width: 1020px) {
  //   margin-bottom: 1em;
  // }
}

.r-mb-22 {
  margin-bottom: 6em;
}

.r-mb-3 {
  margin-bottom: 3em;
}

.r-mb-4 {
  margin-bottom: 4em;
}

.r-mb-5 {
  margin-bottom: 5em;
}

.r-mb-6 {
  margin-bottom: 6em;
}

.r-mt-2 {
  margin-top: 2em;
}

.r-mt-3 {
  margin-top: 3em;
}

.r-mt-4 {
  margin-top: 4em;
}

.r-mt-5 {
  margin-top: 5em;
}

.r-mw-20 {
  max-width: 20em;
}

.r-mnw-20 {
  min-width: 20em;
}

.r-ml-10 {
  margin-left: 10em;

  @media screen and (max-width: 1025px) {
    margin-left: 16px;
  }
}

.r-pt-3 {
  padding-top: 3em;

  @media screen and (max-width: 740px) {
    padding-top: 0px;
  }
}

.r-pt-5 {
  padding-top: 5em;

  @media screen and (max-width: 740px) {
    padding-top: 0px;
  }
}

.r-pt-6 {
  padding-top: 6em;

  @media screen and (max-width: 740px) {
    padding-top: 0px;
  }
}

.r-mw-13 {
  max-width: 13em;
}

.r-mr-point-five {
  margin-right: 0.5em;
}

.r-mt-0 {
  margin-top: 0;
}

.dark-blue-thin-border {
  // border: 1px solid $uf-r2021-dark-blue;
}

.ml-300-px {
  margin-left: 13em;

  @media (min-width: 740px) and (max-width: 1600px) {
    margin-left: 0;
  }

  @media screen and (max-width: 740px) {
    margin-left: 0;
  }
}

.flexed-in-row {
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 740px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
}

.swag {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1747px) {
    display: inline;
  }
}

.secondapply {
  display: flex;
  justify-content: center;

  flex-direction: row;

  @media screen and (max-width: 770px) {
    flex-direction: column;
    margin: 0;

    align-items: center;

    justify-content: center;
    display: flex;
  }
}

.contact-section-border {
  border: 1px solid #afafaf;
  padding: 3em;
  margin-bottom: 5em;

  @media screen and (max-width: 740px) {
    text-align: center;
    padding: 2em;
  }
}

.faq-inside-content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #fff;
  max-width: 67em;
}

// Color classes
.uf-dark-blue {
  color: $uf-r2021-dark-blue;
}

.ovfl-scroll {
  overflow: scroll;
}

.fs-30 {
  font-size: 30px;
}

.r-ml-150px {
  margin-left: 150px;
}

.r-p-13px {
  padding: 13px;
}

.uf-teal {
  color: $uf-r2021-teal;
}

.column-container-right ul.hide-bullet li:before {
  content: "";
  color: transparent;
  display: inline-block;
  margin-right: 10px;
  font-size: 30px;
  position: absolute;
  margin-left: -38px;
  margin-top: -7px;
}

.td-none {
  text-decoration: none;
}

.fs-4p-m {
  @media screen and (max-width: 740px) {
    font-size: 4px;
  }
}

.blue-square-li {
  height: 16px;
  width: 16px;
  background-color: #002c75;
  margin-right: 10px;
  margin-left: -38px;
  margin-top: 10px;
}

.blue-square-adjust {
  margin-top: -24px;
}

.content ul.no-bullet h3.blue-square-adjust {
  font-size: 1.85rem;
}

.content ul.no-bullet p {
  font-size: 1em;
}

.side-nav-svg {
  float: right;
  margin-left: 5px;
  margin-top: 6px;
  position: relative;
}

.margbut {
  margin-left: 122px;

  @media screen and (max-width: 1000px) {
    margin-left: 0.3em;
  }
}

.buttonwidthfix {
  width: 299px;
  text-align: center;
}

.spiggle {
  width: 299px;
  text-align: center;
  -webkit-appearance: 0 !important;

  @media screen and (max-width: 740px) {}
}

.down-arrow-main-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-top: 5vh;

  @media screen and (max-width: 740px) {
    padding-top: 10px;
    margin-top: 10vh;
  }
}

.down-arrow-main-container svg {
  width: 66px;
  height: 66px;
}

.typing-text {
  white-space: pre-wrap;
  margin-top: 0;
  color: #fff;
  font-family: gentona-book, Arial, sans-serif;
}

.r-mh-1200 {
  min-height: 1200px;
}

.r-mh-0 {
  max-height: 0;
}

iframe {
  border: 10px solid #fff;
}

//nav style
:root {
  --bgColor: #002c75;
  --txtColor: #ffffff;
  --borColor: rgba(0, 0, 0, 0);
  --sizeVar: 8px;
  --textPrimary: #4b4760;
  --textSecondary: #7f7989;
  --borderColor: #cccccc;
  --invertButton: #002c75;
}

.flexDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin: 5px;
  font-family: gentona-bold, Arial, sans-serif;
  font-weight: 400;
  font-size: calc(var(--sizeVar) * 1.75);
}

.selectWrapper {
  width: 100%;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms linear 0s;
  padding-top: calc(var(--sizeVar) / 2);
  display: flex;
  justify-content: center;
}

.multiSelect {
  font-family: gentona, Arial, sans-serif;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  border-radius: calc(var(--sizeVar) / 2);
  position: absolute;
  width: 200px;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #ffffff;
  transition: transform 300ms ease-in-out 0s, clip-path 300ms ease-in-out 0s;
}

.multiSelect div {
  color: var(--textPrimary);
  padding: 16px;
  width: auto;
  cursor: pointer;
}

.multiSelect div:not(.ms-space):hover {
  background-color: #f6f6f6;
}

.selectWrapper2 {
  width: 100%;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms linear 0s;
  padding-top: calc(var(--sizeVar) / 2);
  display: flex;
  justify-content: center;
}

.multiSelect2 {
  font-family: gentona, Arial, sans-serif;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  border-radius: calc(var(--sizeVar) / 2);
  position: absolute;
  width: 220px;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #ffffff;
  transition: transform 300ms ease-in-out 0s, clip-path 300ms ease-in-out 0s;
}

.multiSelect2 div {
  color: var(--textPrimary);
  padding: 16px;
  width: auto;
  cursor: pointer;
}

.multiSelect2 div:hover {
  background-color: #f6f6f6;
}

.selectWrapper3 {
  width: 100%;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms linear 0s;
  padding-top: calc(var(--sizeVar) / 2);
  display: flex;
  justify-content: center;
}

.multiSelect3 {
  font-family: gentona, Arial, sans-serif;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  border-radius: calc(var(--sizeVar) / 2);
  position: absolute;
  width: 200px;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #ffffff;
  transition: transform 300ms ease-in-out 0s, clip-path 300ms ease-in-out 0s;
}

.multiSelect3 div {
  color: var(--textPrimary);
  padding: 16px;
  width: auto;
  cursor: pointer;
}

.multiSelect3 div:hover {
  background-color: #f6f6f6;
}

.selectWrapper4 {
  width: 100%;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms linear 0s;
  padding-top: calc(var(--sizeVar) / 2);
  display: flex;
  justify-content: center;
}

.multiSelect4 {
  font-family: gentona, Arial, sans-serif;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  border-radius: calc(var(--sizeVar) / 2);
  position: absolute;
  width: 200px;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #ffffff;
  transition: transform 300ms ease-in-out 0s, clip-path 300ms ease-in-out 0s;
}

.multiSelect4 div {
  color: var(--textPrimary);
  padding: 16px;
  width: auto;
  cursor: pointer;
}

.multiSelect4 div:hover {
  background-color: #f6f6f6;
}

.selectWrapper5 {
  width: 100%;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms linear 0s;
  padding-top: calc(var(--sizeVar) / 2);
  display: flex;
  justify-content: center;
}

.multiSelect5 {
  font-family: gentona, Arial, sans-serif;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  border-radius: calc(var(--sizeVar) / 2);
  position: absolute;
  width: 200px;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #ffffff;
  transition: transform 300ms ease-in-out 0s, clip-path 300ms ease-in-out 0s;
}

.multiSelect5 div {
  color: var(--textPrimary);
  padding: 16px;
  width: auto;
  cursor: pointer;
}

.multiSelect5 div:hover {
  background-color: #f6f6f6;
}

.bottomBorder {
  border-bottom: 1px solid var(--borderColor);
}

.topBorder {
  border-top: 1px solid var(--borderColor);
  background-color: #f6f6f6;
}

.iconDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noSpace {
  justify-content: flex-start;
  gap: 6px;
}

.titleDiv {
  pointer-events: none;
  font-weight: 700;
}

.justHover i {
  opacity: 0;
}

.justHover:hover i {
  opacity: 1;
}

.multiSelect .placeholder {
  color: var(--textSecondary);
  font-style: italic;
}

.multiSelect .narrow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.multiSelect i {
  color: var(--textSecondary);
}

.multiSelect {
  transform: translateX(100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.multiSelect:nth-of-type(1) {
  transform: translateX(0);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.flexDiv button {
  font-family: gentona, Arial, sans-serif;
  font-size: calc(var(--sizeVar) * 1.75);
  font-weight: 500;
  border: none;

  padding: var(--sizeVar) calc(var(--sizeVar) * 2);
  border-radius: calc(var(--sizeVar) / 2);
  cursor: pointer;
  background-color: var(--bgColor);
  color: var(--txtColor);
  margin: 0;
}

.flexDiv button:focus {
  --borColor: rgba(0, 0, 0, 0.4);
}

.flexDiv button:hover {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
}

.flexDiv button:active {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
}

.flexDiv button:focus {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
}

.flexDiv button:target {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
}

.flexDiv a {
  font-family: gentona, Arial, sans-serif;
  font-size: calc(var(--sizeVar) * 1.75);
  font-weight: 500;
  border: none;
  outline: none;
  padding: var(--sizeVar) calc(var(--sizeVar) * 2);
  border-radius: calc(var(--sizeVar) / 2);
  cursor: pointer;
  background-color: var(--bgColor);
  color: var(--txtColor);
  text-decoration: none;
}

.flexDiv a:focus {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
  text-decoration: none;
}

.flexDiv a:hover {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
  text-decoration: none;
}

.flexDiv a:active {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
  text-decoration: none;
}

.tri_btn:hover {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
}

.tri_btn:active {
  --bgColor: #d1ecf1;
  color: var(--invertButton);
}

.multiSelect img {
  width: 11px;
}

.flexNavContainerDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--bgColor);
  align-items: center;
  position: fixed;
  width: 100vw;
  z-index: 3000;
  top: 0;
  white-space: nowrap;
}

.pushFlexed {
  margin-left: auto;
}

.check-status-icon {
  margin-left: 4px;
}

.uf-logo {
  height: 51px;
  width: 51px;
  margin-left: 0px;
  padding-left: 5px;
  padding-right: 5px;
  fill: white;
  background-color: #fa4616;
}

a:hover .uf-logo,
a:focus .uf-logo,
a:active .uf-logo {
  fill: #002c75;
  background-color: white;
}

.uf-logo-container {
  height: 51px;
}

.admissions-top-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: auto;
}

.admissions-home-container {
  color: white;
  font-family: gentona, Arial, sans-serif;
  font-size: calc(var(--sizeVar) * 1.75);
  font-weight: 500;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 10px;
  display: flex;
  align-items: baseline;
}

.admissions-home-container:hover,
.admissions-home-container:active,
.admissions-home-container:focus {
  background-color: transparent;
  color: #c7c9c8;
  text-decoration: none;
}

.admissions-home-container h1 {
  font-family: gentona-bold, Arial, sans-serif;
  font-size: calc(var(--sizeVar) * 3.4);
  font-weight: 700;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  display: inline-block;
  padding: 0;
  color: white;
}

.admissions-home-container h1:hover {
  background-color: transparent;
  color: #cce5ff;
}

svg.admissions-home-icon {
  width: calc(var(--sizeVar) * 3);
  display: inline-block;
  padding-right: 3px;
  padding-left: 3px;
}

.admissions-home-ham {
  color: white;
}

.mr-nav {
  margin-right: 6.5em;
}

.ham-menu {
  display: none;
  color: white;
  background-color: #002c75;
}

.ham-menu button {
  color: white;
  background-color: #002c75;
}

.top-nav-arrow {
  width: 11px;
  height: 11px;
}

.top-nav-arrow:hover {
  // color: #cce5ff;
  width: 11px;
  height: 11px;
}

#menu-1 {
  max-height: 85vh;
  overflow: auto;

  @media screen and (max-width: 740px) {
    max-height: 70vh;
  }
}

.skip-style {
  height: 12vh;
  background-color: #002c75;
  color: white;
}

@media only screen and (max-width: 1345px) {
  #uf-homepage-mobile {
    height: 51px;
    background-color: #fa4616;
  }

  .ham-menu {
    display: flex;
    color: white;
    background-color: #002c75;
    position: fixed;
    width: 100vw;
    bottom: 0;
    z-index: 5000;
    justify-content: space-between;
    align-items: center;
  }

  .ham-menu button {
    color: white;
    background-color: #002c75;
    position: relative;
    float: right;
    padding: 5px;
    margin: 0;
  }

  .ham-menu .admissions-home-container h1 {
    margin-top: 10px;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button-bevel !important;
  }

  .flexNavContainerDiv {
    display: none;
    flex-direction: column;
    height: 96vh;
    padding-top: 4vh;
  }

  .menu-scroll {
    max-height: 78vh;
    overflow: scroll;
  }

  .flexDiv button {
    font-size: calc(var(--sizeVar) * 2.5);
  }

  .flexDiv a {
    font-size: calc(var(--sizeVar) * 2.5);
  }

  .admissions-home-ham {
    color: white;
    width: 40px;
    // border: 2px solid white;
    padding: 2px;
    -webkit-appearance: button-bevel;
  }

  .pushFlexed {
    margin: 5px;
  }

  .uf-logo-container {
    display: none;
  }

  .admissions-top-container {
    display: none;
  }

  .mr-nav {
    margin-right: 0;
  }

  .uf-logo {
    background-color: transparent;
  }
}

@media only screen and (max-width: 768px) {
  .multiSelect {
    font-family: gentona, Arial, sans-serif;
    border-radius: calc(var(--sizeVar) / 2);
    position: absolute;
    width: 90vw;
    left: auto;
    right: auto;
    height: 63vh;
    overflow: scroll;
  }

  .multiSelect2 {
    font-size: calc(var(--sizeVar) * 2.2);
    border-radius: calc(var(--sizeVar) / 2);
    position: absolute;
    width: 90vw;
    left: auto;
    right: auto;
    height: 55vh;
    overflow: scroll;
  }

  .multiSelect2,
  .multiSelect3 {
    font-size: calc(var(--sizeVar) * 2.2);
    border-radius: calc(var(--sizeVar) / 2);
    position: absolute;
    width: 90vw;
    left: auto;
    right: auto;
    height: 45vh;
    overflow: scroll;
  }

  .multiSelect4 {
    border-radius: calc(var(--sizeVar) / 2);
    position: absolute;
    width: 90vw;
    left: auto;
    right: auto;
    font-size: calc(var(--sizeVar) * 2.2);
    height: 35vh;
    overflow: scroll;
  }
}

// side-nav mobile

#side-link-menu-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  #side-link-menu-mobile {
    display: flex;
    // justify-content: center;
    flex-wrap: nowrap;
  }

  .center-content-area .column-container-right {
    margin-top: 0;
    width: 100vw;
  }

  .column-container-right.top-margin-5 {
    margin-top: 5vh;
    width: 100vw;
  }

  .center-content-area .row-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

}

.scolling-container {
  margin-top: 3em;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  width: 100vw;
  background-color: transparent;
  color: #07317b;
  -ms-flex-direction: row;
  flex-direction: row;
  // border-top: 1px solid #07317b;
  // border-bottom: 1px solid #07317b;
  overflow-x: scroll;

  .scrolling-wrapper-item {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0.3em 10px;
    font-size: 1.25em;
  }

  .scrolling-wrapper-item a {
    color: #fff;
    text-decoration: none;
    border: 2px solid #07317b;
    border-radius: 15px;
    padding: 0.2em 0.4em;
    background-color: #07317b;
    font-size: .7em;
  }
}

@media screen and (max-width: 768px) {
  .scolling-container {
    margin-top: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    width: 100vw;
    background-color: #ffffff;
    color: #07317b;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow-x: scroll;
    position: fixed;
    bottom: 3.6em;
    z-index: 50000;
    max-width: fit-content;
    margin: 0 auto;
    justify-content: flex-start;
  }
}

// .scolling-container::before {
//   content: "";
//   position: absolute;
//   left: 10;
//   top: 18%;
//   display: block;
//   border-right: 2px solid #fa4616;
//   border-bottom: 2px solid #fa4616;
//   width: 10px;
//   height: 10px;
//   transform: translate(-50%,-50%) rotate(135deg);
// }

// .scolling-container::after {
//   content: "";
//   position: absolute;
//   right: 0;
//   top: 18%;
//   display: block;
//   border-right: 2px solid #fa4616;
//   border-bottom: 2px solid #fa4616;
//   width: 10px;
//   height: 10px;
//   transform: translate(-50%,-50%) rotate(-45deg);
// }

.apply-arrow {
  transform: rotate(0deg);
}

.academics-arrow {
  transform: rotate(0deg);
}

.cost-and-aid-arrow {
  transform: rotate(0deg);
}

.explore-arrow {
  transform: rotate(0deg);
}

// iframe contacts
.frame-size {
  min-height: 1200px;
  height: 1200px;
}

.fs-pt-6 {
  font-size: 0.6em;
}

// admission logo
.logo-admissions {
  font-size: 40px;
  font-family: gentona, Arial, sans-serif;
  color: #000;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  // padding-top: 9px;
  font-family: gentona-bold, Arial, sans-serif;
  font-size: calc(var(--sizeVar) * 3.4);
  font-weight: 700;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  display: inline-block;
  padding: 0;
  color: white;
  font-weight: 100;
}

.logo-admissions:hover {
  background-color: transparent;
  color: #cce5ff !important;
  text-decoration: none;
}

//ipad fixes
// @media (min-width: 741px) and (max-width: 1344px) {
// }


@media (min-width: 769px) and (max-width: 1199px) {


  .scolling-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
    padding-bottom: 15px;

    .scrolling-wrapper-item {
      padding-right: 20px;
      font-size: 1.25em;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .scrolling-wrapper-item a {
      color: white;
      text-decoration: none;
    }
  }

  .visitimgpage {
    padding: 11px 26px 10px 39px;
    margin-top: -5px;
    font-size: 2em;
  }

  @media only screen and (max-width: 768px) {
    .visitimgpage {
      padding: 0;
      margin-top: -5px;
      font-size: 2em;
    }
  }

  .center-content-area .column-container-left {
    margin-top: 8vh;
  }


  .center-content-area .column-container-right {
    margin-top: 10vh;
    padding-left: 2vw;
  }

  .breadcrumb-2021 {
    display: none;
  }

  .top-link {
    bottom: 180px;
    right: -14px;
  }

  .header-cont .heading-cont img {
    display: none;
  }

  .header-cont .heading-cont {
    display: flex;
    justify-content: center;
  }

  .typing-container {
    margin-top: 55vh;
  }

  .heading-container {
    padding-top: 0;
  }

  .heading-container-di {
    padding-top: 0;
  }

  .side-nav-container-line {
    border-left: 5px solid #fa4616;
    padding-left: 25px;
    max-width: 220px;
  }

  .side-nav-container-line-di {
    border-left: 5px solid #fa4616;
    padding-left: 25px;
    max-width: 220px;
  }

  // .side-link-container {
  //   position: absolute;
  //   display: flex;
  //   flex-direction: column;
  //   padding-top: 30px;
  //   max-width: 220px;
  //   margin-left: -5vw;
  // }

  .r-mw-13 {
    max-width: 13em;
    margin-top: 1em;
  }

  .flexNavContainerDiv {
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 92vh;
    padding-top: 0vh;
  }

  .multiSelect {
    font-family: gentona, Arial, sans-serif;
    border: 1px solid var(--borderColor);
    box-sizing: border-box;
    border-radius: calc(var(--sizeVar) / 2);
    position: absolute;
    width: 700px;
    left: -275%;
    right: 0;
    overflow: hidden;
    background: #fff;
  }

  .multiSelect2 {
    width: 700px;
    left: -160%;
  }

  .multiSelect3 {
    width: 700px;
    left: -160%;
  }

  .multiSelect4 {
    width: 700px;
    left: -200%;
  }
}


// @media (max-width: 768px) {
//   .side-link-container {
//     display: none;
//   }
//   .column-container-left {
//     display: none;
//   }
// }

.uf-search-display {
  display: flex;
  padding: 0 1em 0 0;

  @media (max-width: 1200px) {
    display: none;
  }
}

.search-glass {
  line-height: 1.2;
}

.top-nav-search {
  flex-direction: row;
  position: fixed;
  right: 4vw;
  transition: 1s;
  display: none;
  animation: search-fade 1s forwards;
  padding-right: 0;
  width: 25vw;
  padding-right: 1.5em;
}

.top-nav-search input {
  color: white;
}

@keyframes search-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.site-search-input {
  font-size: 16px;
  padding: 6px;
  width: 100%;
  outline: 0;
  border-radius: 0;
  background: transparent;
  border: 2px solid white;
  caret-color: white;
  color: white;
}

input[type="search"].site-search-input::-webkit-input-placeholder {
  color: white;
}

.flexDiv a.search-glass:focus {
  --bgColor: #cce5ff;
  color: var(--invertButton);
  text-decoration: none;
}

//chatbot
.fatv18 {
  display: none !important;
}

@media (max-width: 1201px) {
  .MaxAI .fatv18 {
    bottom: 80px !important;
    right: 10px !important;
  }
}

// @media (max-width: 1201px) {
.MaxAI .fatv18 {
  bottom: 180px !important;
  right: 10px !important;
}

// }

// .MaxAI .fatv18 {
//   @media (max-width: 1201px) {
//     .MaxAI .fatv18 {
//       bottom: 180px !important;
//       right: 10px !important;
//     }
//   }
// }

.admissions-home-container:hover,
.admissions-home-container:focus,
.admissions-home-container:active {
  .admissions-home-icon {
    color: #c7c9c8;
  }

  .logo-admissions {
    color: #c7c9c8;
  }
}

.webm-button {
  display: none;
}

@media only screen and (max-width: 768px) {
  .external-icon-main-nav {
    margin-top: 4px;
    margin-bottom: 0;
  }

  .feather-download {
    margin-top: 4px;
    margin-bottom: 0;
  }

  .feather-calendar {
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  grid-template-rows: repeat(21, 0.5vw);
  grid-gap: 1.5vw;
  background-color: #fff;
  padding: 1.5vw;

  @media only screen and (max-width: 499px) {
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(27, 6vw);
  }
}

.grid-container>div {
  // background-color: #002c75;
  text-align: center;

  font-size: 30px;
}

.grid-container img,
article.df-page-wrapper img,
img.df-header-img {
  font-size: 12px;
  color: #fff;
}

.next-steps {
  height: 366px;
  background-color: #07317b;
  margin-top: 200px;
}

.next-steps2 {
  height: 263px;
  background-color: #07317b;
}

.questions {
  padding-top: 163px;
}

.articlePadding {
  // padding: 3em 5em;
  padding: 4.7vw 7.8vw;
}

.articlePaddingTopBottom {
  // padding: 5em 0;
  padding: 7.8vw 0;
}

.articlePaddingTopBottomArticle {
  // padding: 2em 0;
  padding: 1.5vw 0;
}

@media screen and (max-width: 499px) {
  .articlePadding {
    padding: 0vw;
  }

  .articlePaddingTopBottom {
    padding: 0vw;
  }

  .articlePaddingTopBottomArticle {
    padding: 0vw;
  }
}

.df-mobile-section-padding {
  padding: initial;
}

@media screen and (max-width: 499px) {
  .df-mobile-section-padding {
    padding: 8vw;
  }
}

.questionsh22 {
  text-align: center;
  font-size: 3.4em;
  font-weight: 600;
  font-family: Gentona;
  letter-spacing: -0.45px;
  color: #07317b;
  opacity: 1;
  padding-bottom: 0;
  font-size: 3.4em;
  font-weight: 600;
  font-family: Gentona;
  letter-spacing: -0.45px;
  text-align: left;
  margin: 0;
  padding-top: 28px;
}

.questionsh3 {
  text-align: center;
  font: normal normal bold 2em Gentona;
  letter-spacing: 0px;
  color: #07317b;
  text-transform: uppercase;
  opacity: 1;
}

.questionsh1 {
  text-align: center;
  font: normal normal bold 1em Gentona;
  letter-spacing: 0.75px;
  color: #07317b;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 29px !important;
  position: relative;
  border-bottom: none;
  // font-size: 1em;
  font-size: 1.6vw;
}

@media screen and (max-width: 499px) {
  .questionsh1 {
    font-size: 5.6vw;
  }
}

.questionsh1:after {
  content: "";
  background: #fa4616;
  position: absolute;
  height: 50%;
  width: 2px;
  transform: rotate(90deg);
  left: 50%;
  bottom: 0;
}

.flexzzz {
  display: inline-flex;
}

.gibimg {
  display: block;
  height: 80px;
  width: auto;
  text-align: center;
  padding: 0;
}

.calimg {
  display: block;
  // height: 80px;
  // width: auto;
  text-align: center;
  padding: 0;
  // margin-right: 2em;
  // height: 9vh;
  width: 8vw;
  margin-right: 2.5vw;
}

@media screen and (max-width: 499px) {
  .calimg {
    width: 24vw;
    margin-bottom: 8vw;
  }

}

.gatorFinancialimg {
  display: block;
  // height: 150px;
  // width: auto;
  text-align: center;
  padding: 0;
  // margin-right: 2em;
  // height: 19.5vw;
  width: 14vw;
  margin-right: 2.5vw;
}

@media screen and (max-width: 499px) {
  .gatorFinancialimg {
    width: 42vw;
    margin-bottom: 8vw;
  }
}

.middleOffsetPadding {
  // padding-left: 16em;
  // padding-right: 16em;
  padding-left: 25vw;
  padding-right: 25vw;
}

.middleOffsetPadding2 {
  // padding-left: 12em;
  // padding-right: 16em;
  padding-left: 18.7vw;
  padding-right: 25vw;
}

.middleOffsetPadding3 {
  // padding-left: 12em;
  // padding-right: 16em;
  padding-left: 18.7vw;
  padding-right: 15vw;
}

@media screen and (max-width: 499px) {
  .middleOffsetPadding {
    padding: 8vw;
  }

  .middleOffsetPadding2 {
    padding: 8vw;
  }

  .middleOffsetPadding3 {
    padding: 8vw;
  }
}

.fpgimg {
  padding: 34px;
  height: 469px;
  margin-top: -91px;
  margin-right: 14px;
}

.greydivleft {
  background-color: #f8f9fa;
  max-width: 511px;
  margin: 0 auto;
  border-radius: 93px;
  margin-top: 63px;
}

.greydivright {
  margin: 20px;
  background-color: #f8f9fa;
  margin-top: 51px;
  max-width: 452px;
  padding: 31px;
  float: left;
  text-align: left;
}

.threeColStats {
  margin: 1vw 3vw;
}

@media screen and (max-width: 499px) {
  .threeColStats {
    margin: 4vw 0;
  }
}

.percent {
  font: italic normal 600 4em Gentona-bold;
  letter-spacing: -1.11px;
  color: #fa4616;
  // font-size: 4.4em;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 6.8vw;
}

@media screen and (max-width: 499px) {
  .percent {
    text-align: left;
    font-size: 22.8vw;
  }
}

.percent2 {
  font: italic normal 600 4em Gentona-bold;
  letter-spacing: -1.11px;
  color: #fa4616;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 6.8vw;
}

@media screen and (max-width: 499px) {
  .percent2 {
    text-align: left;
    font-size: 18.8vw;
  }
}

.df-half-desktop {
  max-width: 66.6%;
  margin: 0 4vw;
}

@media screen and (max-width: 499px) {
  .df-half-desktop {
    max-width: 100%;
    margin: 2vw 0;
  }
}

.greyback {
  background-color: #f8f9fa;
}

.fpg {
  margin-top: 130px;
  margin-bottom: 56px;
}

.gibh2 {
  padding: 16px;
  padding-bottom: 120px;
  font-size: 3.2em;
  font-size: 3em;
  font-weight: 600;
  font-family: Gentona;
  letter-spacing: -0.45px;
  padding-top: 102px;

  text-align: center;
  color: #07317b;
  opacity: 1;
}

.stepsh3 {
  color: $uf-r2021-teal;

  font-size: 3em;
  padding-bottom: 36px;
  margin: 0 auto;
  text-align: center;
  font: normal normal 700 3em Gentona;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;

  padding-top: 60px;
  font-size: 3em;
  padding-bottom: 25px;
  margin: 0 auto;
}

.stepsh33 {
  text-align: center;
  letter-spacing: 0;
  opacity: 1;
  color: #fff;
  padding-top: 73px;
  text-align: center;
  font: normal normal 600 3em Gentona;
  letter-spacing: -0.45px;
  color: #ffffff;
  opacity: 1;
  font-size: 3em;
  padding-bottom: 36px;
  margin: 0 auto;
}

.mobile-no-space {
  @media only screen and (max-width: 900px) {
    width: 100vw;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.social {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.socialsimg {
  // width: 46px;
  width: 5.1vw;
  padding: 0 0.7vw 0.7vw;
  margin-bottom: 20px;
}

@media screen and (max-width: 499px) {
  .socialsimg {
    width: 17.1vw;
    padding: 0 3vw 3vw;
  }
}

.socialsimg:hover {
  transform: scale(1.1);
}

.decLine {
  width: 8vw;
}

.decLineOrange {
  width: 7vw;
}

.stepsh33soc {
  text-transform: uppercase;
  opacity: 1;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  // font-size: 1.1em;
  font-size: 1.7vw;
  font-weight: 800;
  font-family: "gentona";
  letter-spacing: 7px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  padding-bottom: 1em;
  margin-top: 3em;
}

@media screen and (max-width: 499px) {
  .stepsh33soc {
    font-size: 6.4vw;
    margin: 1em auto 0;
    padding-top: 1.5em;
  }
}

.dfStatImg {
  width: 12vw;
  margin-bottom: 1.8vw;
}

@media screen and (max-width: 499px) {
  .dfStatImg {
    width: 52vw;
    margin-bottom: 4vw;
  }
}

.greytri2 {
  height: 60px;
  width: 66px;
  display: block;
  margin: 0 auto;
  margin-bottom: -81px;
  margin-top: 35px;
}

.fpgh3 {
  text-align: left;
  font: normal normal 600 45px/49px Gentona;
  letter-spacing: -0.45px;
  color: #07317b;
  opacity: 1;
  padding-bottom: 0px;
  margin-bottom: 11px;
}

.fpgh4 {
  text-align: left;
  font: normal normal 800 27px/32px Gentona;
  letter-spacing: 0.54px;
  opacity: 1;
  letter-spacing: 0.54px;
  color: #000;
  margin-top: 0px;
  padding-top: 0px;
  font-family: "gentona-bold";
  // font-size: 1.1em;
  font-size: 1.7vw;
  margin: 0;
  padding: 0;
  padding-bottom: 1vw;
}

@media screen and (max-width: 499px) {
  .fpgh4 {
    font-size: 5.7vw;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 499px) {
  .mobileTitleOffsetPadding {
    padding: 4vw 4vw 4vw 0;
  }
}

.fpgh4-2 {
  text-align: left;
  font: normal normal 800 27px/32px Gentona;
  letter-spacing: 0.54px;
  opacity: 1;
  letter-spacing: 0.54px;
  color: #000;
  margin-top: 0px;
  padding-top: 0px;
  font-family: "gentona-bold";
  // font-size: 1.1em;
  font-size: 1.7vw;
  margin: 0;
  padding: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 499px) {
  .fpgh4-2 {
    font-size: 5.7vw;
    margin-bottom: 2vw;
    padding: 10vw;
    text-align: center;
  }
}

.fpgh5 {
  font: normal normal 800 27px/32px Gentona;
  letter-spacing: 0.54px;
  opacity: 1;
  letter-spacing: 0.54px;
  color: #000;
  margin-top: 0px;
  font-family: "gentona-bold";
  margin: 0;
  // font-size: .7em;
  // line-height: 14px;
  line-height: 1.6vw;
  padding: 1vw;
  font-size: 1.1vw;
}

@media screen and (max-width: 499px) {
  .fpgh5 {
    font-size: 5.1vw;
    line-height: 5.6vw;
    padding: 1em;
  }
}

.fpgh6 {
  font: normal normal 800 27px/32px Gentona;
  letter-spacing: 0.54px;
  opacity: 1;
  letter-spacing: 0.54px;
  color: #000;
  margin-top: 0px;
  padding-top: 0px;
  font-family: "gentona-bold";
  // margin: 0;
  // font-size: .9em;
  font-size: 1.4vw;
  padding: 0;
  margin: 0.7vw;
}

@media screen and (max-width: 499px) {
  .fpgh6 {
    font-size: 5.4vw;
  }
}

.greyboxtext2 {
  font-family: "gentona-bold";
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-top: -15px;
  padding: 20px;
  color: black;
  font-size: 25px;
  text-align: center;
  padding-left: 22px;
}

.greyboxtextp {
  letter-spacing: 0;
  color: #000;
  opacity: 1;
  font-family: gentona;
  font-size: 2em;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0px;
  padding: 0;
  margin: 0;
}

.backarrow {
  background-image: url(/img/dream/long2.jpg);

  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  margin-top: -49px;

  height: 425px;

  @media only screen and (max-width: 600px) {
    height: 377px;
  }
}

.pad604 {
  padding: 59px;

  @media only screen and (max-width: 1020px) {
    padding: 20px;
  }
}

.pad605 {
  padding: 46px;

  @media only screen and (max-width: 1020px) {
    padding: 30px;
  }
}

.applybutton77 {
  border-radius: 36px;
  border: 2px solid #fff;
  background-color: white;
  text-decoration: none;
  color: #07317b;
  font-family: "gentona-medium";
  margin-bottom: 20px;
  margin-right: 13px;
  letter-spacing: 2px;
  opacity: 1;
  padding: 0.6em 1.5em 0.5em;
  font-weight: 700;
  border-radius: 35px;
  outline: none;
  text-decoration: none;
  font-size: 10px;
  font-weight: 800;
  max-width: 150px;
  text-align: center;
}

.applybutton77:hover,
.applybutton77:focus,
.applybutton77:active {
  background: #07317b;
  color: #fff;
  border: 2px solid #fff;
  text-decoration: none;
}

.gibbutton86 {
  border-radius: 30px;
  border: none;
  background: #07317b;
  margin-bottom: 20px;
  padding: 15px 22px 12px;
  color: #fff;
  margin-right: 13px;
  font-weight: 500;
  letter-spacing: 2px;
  opacity: 1;
  font-family: "gentona-medium";
  font-size: 16px;
  border: 2px solid #07317b;
  text-decoration: none;
  display: inline-flex;
}

.gibbutton86:hover,
.gibbutton86:focus,
.gibbutton86:active {
  background: #fff;
  color: #07317b;
  border: 2px solid #07317b;
  text-decoration: none;
}

.gibbutton78 {
  border-radius: 30px;
  font-size: 15px;
  background: #07317b;
  margin-bottom: 20px;
  padding: 15px 30px 12px;
  color: #fff;
  margin-right: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  opacity: 1;
  width: 293px;
  border: 2px solid #07317b;
  text-decoration: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  font-family: "gentona-medium";
}

.gibbutton78:hover,
.gibbutton78:focus,
.gibbutton78:active {
  background: #fff;
  color: #07317b;
  border: 2px solid #07317b;
  text-decoration: none;
}

.gibbutton80 {
  border-radius: 45px;
  border: none;
  background: #07317b;
  margin-bottom: 20px;
  padding: 19px 40px 14px;
  color: #fff;
  margin-right: 13px;
  font-weight: 500;
  letter-spacing: 2px;
  opacity: 1;
  margin: 0 auto;
  text-align: center;
  display: block;
  font-size: 25px;
  font-family: "gentona-medium";
  margin-top: 30px;
  margin-bottom: 35px;
  display: inline-flex;
}

.max-width77 {
  max-width: 989px;
}

.max-width78 {
  max-width: 1100px;
}

.max-width81 {
  max-width: 1202px;
}

.boxxx {
  padding: 22px;
}

.lineimg {
  height: 225px;
  width: auto;
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-top: -185px;
}

.questionsimg {
  // width: 320px;
  width: 35.2vw;
  margin: 0 auto;
  text-align: center;
  display: block;
  // margin-right: 2em;
  margin-right: 2.5vw;
  height: 100%;
}

@media screen and (max-width: 499px) {
  .questionsimg {
    width: 100%;
  }
}

.firstSectionPadding {
  padding-top: 4.7vw;
}

@media screen and (max-width: 499px) {
  .firstSectionPadding {
    padding-top: 0;
  }
}

.questionsimg22 {
  height: 214px;
  width: auto;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.questionsh2 {
  text-align: center;
  // font-size: 1.3em;
  font-size: 2vw;
  font-weight: 800;
  font-family: Gentona;
  letter-spacing: -0.45px;
  color: #07317b;
  opacity: 1;
  padding: 0;
  margin: 0;
  font-family: 'gentona-bold';
}

@media screen and (max-width: 499px) {
  .questionsh2 {
    text-align: left;
    font-size: 5vw;
  }
}

.questionsh2222 {
  text-align: center;
  font-size: 3.4em;
  font-family: Gentona;
  letter-spacing: -0.45px;
  color: #07317b;
  padding: 0;
  margin: 0;
  text-align: left;
  padding-top: 36px;
  font-weight: 600;
}

.questionsp {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: "gentona";
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 400;
}

.questionsp40 {
  letter-spacing: 0;
  opacity: 1;
  font-family: gentona;
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.questionspwhite {
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-family: "gentona";
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 400;
}

.questionspp {
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-family: "gentona";
  font-size: 1.4em;
  line-height: 1.5;
  font-weight: 600;
}

.questionsppwhite {
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-family: "gentona";
  font-size: 1.4em;
  line-height: 1.5;
  font-weight: 600;
}

.questionsp a {
  color: #07317b;
  text-decoration: underline;
  word-break: break-word;
  font-family: "gentona-bold";
  font-size: 1.4em;
}

.questionsp a:hover {
  color: #0e0e0e;
}

.photogridimg1 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photogridimg2 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photogridimg3 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photogridimg4 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photogridimg5 {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}

.photogridimg6 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photogridimg7 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photogridimg8 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.gridremove {
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.df-multi-img {
  padding-right: 0px;
}

.textgrid {
  position: absolute;
  display: inline;
  text-align: center;
  right: 0;
  left: 0;
  padding-top: 81px;

  @media only screen and (max-width: 1200px) {
    padding-top: 77px;
  }
}

.toprow {
  max-width: 1341px;
  margin: 0 auto;
}

.topp {
  letter-spacing: 0;
  color: #000;
  opacity: 1;
  margin: 0 auto;
  line-height: 1.6;
  padding-top: 0px;
  // font-size: 0.9em;
  font-size: 1.4vw;
  font-family: 'gentona';
  // padding-bottom: 2em;
  margin-bottom: 2.5vw;
}

@media screen and (max-width: 499px) {
  .topp {
    margin: 0 0 2.5vw 0;
    font-size: 4.4vw;
    padding-bottom: 4vw;
  }

}

.dreamimg {
  // height: 100px;
  // height: 9.1vh;
  margin: 0 auto;
  text-align: center;
  // width: auto;
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
  float: inherit;
  width: 14vw;
}

@media screen and (max-width: 499px) {
  .dreamimg {
    margin: 0;
    text-align: left;
    width: 49vw;
  }
}

.padtop888 {
  padding-top: 65px;
}

.df-links {
  color: #002c75;
  font-weight: 800;
  font-size: 1.4vw;
}

@media screen and (max-width: 499px) {
  .df-links {
    font-size: 4.7vw;
  }
}

.df-align-items {
  align-items: center;
}

@media screen and (max-width: 499px) {
  .df-align-items {
    align-items: flex-start;
  }
}

.df-display-flex-row-to-col {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 499px) {
  .df-display-flex-row-to-col {
    display: flex;
    flex-direction: column;
  }
}

.learnimg {
  // height: 65px;
  // height: 6.2vh;
  // width: auto;
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 6.4vw;
  // margin-left: 5.6vw;
  // margin-right: 5.6vw;
}

@media screen and (max-width: 499px) {
  .learnimg {
    width: 33.4vw;
  }
}

.fowardh3 {
  text-align: center;
  font: normal normal 600 2.2em Gentona;
  letter-spacing: 0.6px;
  color: #07317b;
  text-transform: uppercase;
  opacity: 1;
  margin: 36px;
}

.toptext {
  text-align: center;
  font: normal normal 800 1.5em Gentona;
  letter-spacing: -0.2px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  line-height: 1.3;
  font-size: .7em;
  font-weight: 800;
}

.textAlingCenter {
  text-align: center;

  @media only screen and (max-width: 900px) {
    text-align: initial;
  }
}



.toptext2 {
  letter-spacing: -0.3px;
  color: #07317b;
  opacity: 1;
  // font-size: 1.4em;
  font-size: 2.2vw;
  font-family: "gentona-bold";
  font-weight: 600;
  margin: 0;
  // padding-bottom: 1em;
  padding-bottom: 1vw;
}

@media screen and (max-width: 499px) {
  .toptext2 {
    font-size: 7.2vw;
    margin-bottom: 8vw;
  }

}

.topimg {
  height: 100px;
  margin: 0 auto;
  width: auto;
  text-align: center;
  display: block;
}

.topimgTemp {
  height: auto;
  width: 160px;
  display: block;
}

.photogridimg img {
  object-fit: cover;
  width: inherit;
  height: inherit;
  transition: 0.5s;

  transform: scale3d(1, 1, 1) rotate(0);
}

.item1 {
  grid-column: 1/8;
  grid-row: 1/7;

  @media only screen and (max-width: 499px) {
    display: none;
  }
}

.item2 {
  grid-column: 1/8;
  grid-row: 7/16;

  @media only screen and (max-width: 499px) {
    display: none;
  }
}

.item3 {
  grid-column: 1/16;
  grid-row: 16/22;

  @media only screen and (max-width: 499px) {
    grid-column: 1/12;
    grid-row: 24/28;

    div {
      background-position-x: 35%;

    }
  }
}

.item4 {
  grid-column: 8/12;
  grid-row: 1/16;

  @media only screen and (max-width: 499px) {
    grid-row: 1/14;
  }
}

.item5 {
  grid-column: 12/23;
  grid-row: 1/9;

  @media only screen and (max-width: 499px) {
    grid-column: 1/8;
    grid-row: 1/6;

    div {
      background-position: center;
    }
  }
}

.item6 {
  grid-column: 12/23;
  grid-row: 9/16;

  text-align: center;
  background-color: #07317b !important;
  position: relative;

  display: grid;
  place-items: center;

  @media only screen and (max-width: 499px) {
    grid-column: 1/12;
    grid-row: 14/24;
  }
}

.forty {
  padding-top: 65px;
  padding-bottom: 122px;
}

.underline2 {
  border-bottom: solid 2px #fa4616;
  display: inline;
  padding: 7px;
}

.item6>.itemtext {
  font-family: gentona-bold, Arial, sans-serif;
  color: #FFF;
  font-size: 1.6vw;
  text-align: center;
  letter-spacing: 0.4vw;
  font-weight: 900;
  line-height: 2.5vw;
  padding: 0;
  text-transform: uppercase;
  opacity: 1;

  @media only screen and (max-width: 499px) {
    font-size: 8vw;
    line-height: 14vw;
    padding: 0 18vw;
  }

  @media only screen and (min-width: 2530px) {
    font-size: 39px;
  }
}

.item7 {
  grid-column: 16/26;
  grid-row: 16/22;

  @media only screen and (max-width: 499px) {
    display: none;
  }
}

.item8 {
  grid-column: 23/33;
  grid-row: 1/16;

  @media only screen and (max-width: 499px) {
    grid-column: 1/8;
    grid-row: 6/14;
  }
}

.item9 {
  grid-column: 26/33;
  grid-row: 16/22;

  @media only screen and (max-width: 499px) {
    display: none;
  }
}

.gallery-preview-image {
  width: 1072px;
  height: 603px;

  @media only screen and (max-width: 768px) {
    width: 327px;
    height: 171px;
    max-width: 327px;
  }
}

.df-universal-padding {
  padding: 2em;
}

.df-u-padding-bottom {
  padding-bottom: 4vw;
}

.df-flexed {
  display: flex;
  flex-wrap: nowrap;
}

.df-flexed-wrap {
  display: flex;
  flex-wrap: wrap;
}

.df-flexed-center {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.df-flexed-center-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.df-image-container {
  flex: 1 1 auto;
}

.df-image-container img {
  max-width: 100%;
}

.df-iframe-container {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.df-bg-frame {
  width: 100%;
  height: auto;
  background-color: #002c75;
}

.df-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.df-flex-row {
  display: flex;
  flex-direction: row;
}

.df-flex-row-no-col {
  display: flex;
  flex-direction: row;
}

.df-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.df-flex-row-first {
  display: flex;
  flex-direction: row;
}

.df-flex-row-nav {
  display: flex;
  flex-direction: row;
}

.df-header-img img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.df-ul {
  list-style-type: none;
}

.df-r-first-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df-ml {
  margin-left: 1em;
}

.df-ta-center {
  text-align: center;
}

.df-mr {
  margin-right: 1em;
}

.df-pr-4 {
  padding-right: 4em;
}

.df-pb-2 {
  padding-bottom: 2em;
}

.df-pb-1 {
  padding-bottom: 1em;
}

.df-bg-blue {
  background-color: #07317b;
}

.df-ml-mr {
  margin-left: 1em;
  margin-left: 1em;
}

.df-c-white {
  color: #ffffff;
}

.df-c-teal {
  color: $uf-r2021-teal;
}

.df-c-blue {
  color: #07317b;
}

.df-blue-bg {
  background-color: #07317b;
}

.df-top-nav {
  // height: 5em;
  align-items: center;
}

#navbuttons {
  padding-top: 1vw;

  @media only screen and (max-width: 499px) {
    padding-top: 6vw;
    justify-content: center;
  }

  a[href="/"],
  a.ufLogoLink {
    // Linted
    margin-left: 1em;

    @media only screen and (max-width: 499px) {
      margin-left: 0;
    }
  }
}

.df-uf-logo {
  height: 3vw;
  margin-left: 1em;

  @media only screen and (max-width: 499px) {
    margin: 0 auto;
  }
}

.df-nav-button {
  color: #07317b;
  background-color: #ffffff;
  padding: 0.4vw 2.3vw 0.4vw;
  border: 2px solid #ffffff;
  border-radius: 3.32vw;
  outline: none;
  text-decoration: none;
  font-size: 1vw;
  font-weight: 800;
}

.buttonOutboundSVG {
  height: 0.85vw;
  margin-bottom: -0.12vw;

  @media only screen and (max-width: 499px) {
    height: 3.2vw;
    margin-bottom: -0.5vw;
  }
}

.df-nav-button:hover,
.df-nav-button:active,
.df-nav-button:focus {
  background-color: #07317b;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  text-decoration: none;
}

.df-nav-button-mobile {
  font-size: 3.8vw;
  color: #07317b;
  background-color: #fff;
  padding: 3.8vw 1.5em;
  font-weight: 700;
  border: 2px solid #fff;
  border-radius: 7.6vw;
  outline: none;
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 70%;
  z-index: 10;
}

.df-nav-button-mobile:hover,
.df-nav-button-mobile:active,
.df-nav-button-mobile:focus {
  background-color: #07317b;
  color: #ffffff;
  text-decoration: none;
}

.df-nav-button-mobile-svg {
  vertical-align: middle;
  margin-left: 0.45vw;
  margin-bottom: 0.45vw;
  // width: 12px;
  // height: 1.35vw;
  height: 3.2vw;
}

.df-mos-button {
  color: #07317b;
  background-color: $uf-r2021-teal;
  border: 2px solid transparent;
  letter-spacing: 2px;
  opacity: 1;
  font-family: "gentona-medium";
  border: 2px solid #07317b;
  padding: 0.6em 1.5em 0.5em 1.5em;
  font-weight: 700;
  border-radius: 35px;
  outline: none;
  text-decoration: none;
  font-size: 10px;
  font-weight: 800;
  max-width: 150px;
  text-align: center;
  margin: 0;
}

.df-mos-button:hover,
.df-mos-button:active,
.df-mos-button:focus {
  background-color: #07317b;
  color: $uf-r2021-teal;
  border: 2px solid $uf-r2021-teal;
  text-decoration: none;
}

.df-mos-button-mobile {
  color: #07317b;
  background-color: $uf-r2021-teal;
  border: 2px solid $uf-r2021-teal;
  font-size: 18px;
  padding: 0.5em 1.5em;
  font-weight: 700;
  border-radius: 15px;
  outline: none;
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 15em;
}

.df-mos-button-mobile:hover,
.df-mos-button-mobile:active,
.df-mos-button-mobile:focus {
  background-color: #07317b;
  color: $uf-r2021-teal;
  border: 2px solid $uf-r2021-teal;
  text-decoration: none;
}

.df-mos-button-mobile-svg {
  vertical-align: middle;
  margin-left: 4px;
  margin-bottom: 4px;
  width: 12px;
  text-decoration: none;
}

.df-flex-end {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.df-flex-start {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.df-bb-n {
  border-bottom: none;
}

.df-bg-bl {
  background-color: #07317b;
}

.df-j-center {
  justify-content: center;
}

.df-a-baseline {
  align-items: baseline;
}

.df-last-button {
  margin-left: 1em;
  margin-right: 2em;
}

.df-admissions {
  letter-spacing: 0;
  line-height: 1.2;
  font-family: gentona-bold, Arial, sans-serif;
  font-size: 2em;
  font-weight: 700;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  display: inline-block;
  padding: 0;
  color: #fff;
}

.df-first-container-inner-1 {
  font-size: 41px;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-family: gentona-bold, sans-serif;
  letter-spacing: 2.52px;
  color: #ffffff;
  text-transform: uppercase;
}

.df-first-container-inner-2 {
  font-size: 100px;
  padding-bottom: 0.3em;
  margin: 0;
  text-align: center;
  font-family: obviously, sans-serif;
  font-weight: 900;
  font-style: italic;
  letter-spacing: 8.65px;
  color: $uf-r2021-teal;
  text-transform: uppercase;
  padding-top: 0;
  line-height: 0.8;
}

.df-first-container-inner-3 {
  font-size: 41px;
  padding-bottom: 0;
  text-align: center;
  margin-top: 0;
  font-family: gentona-bold, sans-serif;
  letter-spacing: 2.52px;
  color: #ffffff;
  text-transform: uppercase;
}

.df-m-img-correct {
  margin-top: -0.1em;
  margin-left: -0.1em;
}

.df-header-img {
  margin-top: 0;
  width: 100%;
  height: 100%;
}

.df-hero-img-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.df-u-padding-top {
  padding-top: 4em;
}

.df-row-wrap-reverse {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap-reverse;
}

.df-dream-apply {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10em 15em 0 0;
}

.df-ml-a {
  margin-left: auto;
}

.df-policies {
  text-decoration: underline;
  word-break: break-word;
  // font-size: 0.7em;
  font-size: 1vw;
}

@media screen and (max-width: 499px) {
  .df-policies {
    font-size: 4vw;
    margin: 0 auto;
    text-align: center;
  }
}

.df-qs_padding {
  padding: 3vw;
}

@media screen and (max-width: 499px) {
  .df-qs_padding {
    padding: 8vw;
  }
}

.df-policies:hover,
.df-policies:active,
.df-policies:focus {
  text-decoration: underline;
  word-break: break-word;
  color: $uf-r2021-teal;
}

.spotify-frame-container {
  background-color: #07317b;
}

.spotify-frame {
  display: flex;
  flex: 0 0 auto;
  width: 80%;
  margin: 0 auto;
  background-color: #07317b;
  margin-bottom: 2em;
}

.spotify-frame iframe {
  border: none;
}

.spotify-title {
  color: $uf-r2021-teal;
  text-align: center;
  font-family: gentona-bold, sans-serif;
  letter-spacing: 7px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2em;
  margin-bottom: 0;
  padding-bottom: 0;
}

.spotify-title-2 {
  color: $uf-r2021-teal;
  text-align: center;
  font-family: gentona-bold, sans-serif;
  letter-spacing: 7px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2em;
  margin-top: 0.3em;
}

.spotify-sub-title {
  color: white;
  font-size: 2em;
  margin-bottom: 0;
  padding-bottom: 0;
}

.spotify-sub-title-2 {
  color: white;
  font-size: 2em;
  margin-top: 0;
}

.spotify-paragraph {
  color: white;
}

.spotify-img {
  width: 15em;
  max-height: 15em;
  margin: 0 auto;
}

.spotify-frame-inner-container {
  width: 60%;
  margin: 0 auto;
  padding-top: 6em;
}

.spotify-s-right {
  margin-left: 4em;
}

.spotify-success-text {
  flex: 30%;
  margin-left: 2em;
}

.testimonial-teal-heading {
  color: $uf-r2021-teal;
  text-align: center;
  font-family: gentona-bold, sans-serif;
  letter-spacing: 7px;
  font-weight: 800;
  font-size: 3em;
}

.hide-on-mobile {
  display: initial;

  @media (max-width: 499px) {
    display: none !important;
  }
}

.df-text-center {
  text-align: center;
}

@media (max-width: 499px) {
  .df-text-center {
    text-align: left;
  }
}

.three-col-section-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 23%;
}

.three-col-section-2 {
  width: 53%;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.text_align-left-mobile {
  @media (max-width: 900px) {
    text-align: left;
  }
}

.df-iframe-box {
  position: relative;
  padding-top: 55.25%;
  border: 8px solid white;

  @media (max-width: 900px) {
    border: 5px solid white;
    padding-top: 54.25%;
  }
}

@media screen and (max-width: 499px) {
  .df-iframe-box {
    border: none;
    padding-top: 56.25%;
  }
}

.three-col-section-3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 23%;
}

.testimonial-teal-heading-2 {
  color: $uf-r2021-teal;
  text-align: center;
  font-family: gentona-bold, sans-serif;
  letter-spacing: 7px;
  font-weight: 800;
  font-size: 3em;
  margin-top: -1em;
  padding: 0.2em;
}

.testimonial-machen-heading {
  font-family: gentona-bold, sans-serif;
  letter-spacing: 0.45px;
  text-align: center;
  font-size: 2.5em;
}

.testimonial-machen-heading-2 {
  font-family: gentona-bold, sans-serif;
  letter-spacing: 0.45px;
  text-align: center;
  margin-top: -1em;
  padding: 0.2em;
  font-size: 2.5em;
}

.testimonial-machen-heading-3 {
  font-family: gentona-bold, sans-serif;
  letter-spacing: 0.45px;
  text-align: center;
  margin-top: -1em;
  padding: 0.2em;
  font-size: 2em;
}

.testimonial-machen-p {
  font-size: 1.6em;
  font-family: gentona-book, sans-serif;
  padding: 0 5em 0 5em;
  max-width: 1266px;
  margin: 0 auto;
  padding: 0 10px 10px;
}

.testimonial-machen-p-2 {
  margin-top: -2em;
  padding: 0.2em;
  font-size: 1.6em;
  font-family: gentona-book, sans-serif;
}

.df-hidden-desktop {
  display: none;
}

.df-show-desktop {
  display: block;
}

@media (max-width: 499px) {
  .testimonial-machen-p {
    padding: 1.5em;
  }

  .questionsp a {
    font-size: 1em;
  }

  .testimonial-machen-p {
    padding: 0 10em;
  }

  .df-fl-center-mobile {
    display: flex;
    justify-content: center;
  }

  .df-show-mobile {
    display: block;
  }

  .next-steps {
    margin-top: 0;
  }

  .questions {
    padding-top: 0;
  }

  .next-steps2 {
    margin-top: 0;
  }

  .padtop888 {
    padding-top: 0;
  }

  .stepsh3 {
    padding-top: 1em;
    font-size: 2.5em;
    padding-bottom: 1em;
  }

  .forty {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.df-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.arrow-h-img {
  max-height: 140px;
  margin-top: 40px;
}

.df-hero-img-container>.arrow-h-img {
  z-index: 1;
}

@media (max-width: 600px) {

  .testimonial-teal-heading,
  .testimonial-teal-heading-2 {
    padding: 0 0.5em;
  }

  .testimonial-machen-p {
    padding: 0 0.3em;
  }

  .df-uf-logo {
    height: 2.2em;
  }

  .df-bg-frame {
    background-image: none;
  }

  .df-mos-button {
    color: #07317b;
    background-color: $uf-r2021-teal;
    border: 2px solid transparent;
    width: 80%;
    text-align: center;
    padding: 0.5em 1.5em;
    font-weight: 700;
    border-radius: 2em;
    outline: none;
    text-decoration: none;
    font-size: 18px;
    margin: 3em auto;
  }
}

.df-pt-point-5 {
  padding: 1.6vw 0;
}

@media (max-width: 499px) {
  .df-blue-column-items {
    margin-right: 0;
    margin-top: 0;
  }

  .df-mos-button {
    color: #07317b;
    background-color: $uf-r2021-teal;
    border: 2px solid transparent;
    width: 80%;
    text-align: center;
    padding: 0.5em 1.5em;
    font-weight: 700;
    border-radius: 2em;
    outline: none;
    text-decoration: none;
    font-size: 18px;
    padding: 0.7em 1em;
    max-width: 150px;
    text-align: center;
    text-decoration: none;
  }

  .testimonial-machen-p {
    padding: 0.5em;
    font-size: 1.5em;
  }

  .testimonial-teal-heading,
  .testimonial-teal-heading-2 {
    font-size: 2em;
  }

  .testimonial-machen-heading,
  .testimonial-machen-heading-2 {
    font-size: 2em;
  }

  .testimonial-machen-p {
    padding: 0;
  }

  .df-hidden-desktop {
    display: block;
  }

  .df-blue-column {
    background-color: #07317b;
    height: auto;
    margin-top: -0.1em;
    max-width: 100vw;
  }

  .df-hidden-mobile {
    display: none;
  }

  .df-can-apply {
    margin-top: -1.4em;
  }

  .df-nav-button {
    color: #07317b;
    background-color: #fff;
    padding: 0.1em 1em;
    font-weight: 700;
    border: 2px solid #fff;
    border-radius: 15px;
    outline: none;
    text-decoration: none;
    font-size: 0.8em;
    z-index: 10;
  }

  .df-nav-button svg {
    vertical-align: middle;
    margin-left: 0;
    margin-bottom: 4px;
    width: 10px;
  }

  .df-hero-img-container img {
    margin-top: 0;
  }

  .df-first-container-inner-1 {
    font-size: 6vw;
  }

  .df-first-container-inner-2 {
    font-size: 13vw;
    text-align: center;
  }

  h1:not(.oos) .df-first-container-inner-2 {
    padding-bottom: 0;
  }

  .df-first-container-inner-3 {
    font-size: 6vw;
  }

  h1.oos .df-first-container-inner-3 {
    font-size: 9vw;
  }

  h1 .applyText {
    padding-top: 0.2em;
    font-weight: 100;
  }

  h1.oos .applyText {
    padding-top: 0;
  }

  .df-m-img-correct {
    margin-top: -0.1em;
    margin-left: -0.1em;
  }

  .df-uf-logo {
    height: 2.3em;
    margin: 0 auto;
  }

  .df-flex-row {
    display: flex;
    flex-direction: column;
  }

  .df-flex-row-no-col {
    display: flex;
    flex-direction: row;
  }

  .df-image-container {
    display: flex;
    justify-content: center;
  }

  .df-image-container img {
    max-width: 33.33%;
  }

  .df-iframe-container {
    display: flex;
    justify-content: center;
  }

  .df-iframe-container iframe {
    max-width: 100%;
  }

  .df-header-img {
    margin-top: -1em;
    height: 130%;
  }

  .df-last-button {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.df-page-wrapper {
  margin: 0px;
  background-color: #07317b;
}

.df-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.df-row-wrap-reverse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  width: 100%;
}

.df-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.df-orange-column {
  background-color: orange;
  height: auto;
}

.df-blue-column {
  background-color: #07317b;
  height: auto;
}

@media screen and (min-width: 800px) {
  .df-column {
    flex: 1;
  }
}

@media screen and (min-width: 1000px) {
  .df-frame-container-side {
    padding: 5em 20em 1em;
  }
}

@media (min-width: 500px) {
  .df-first-container-inner-1 {
    font-size: 3.3vw;
    white-space: nowrap;
    margin: 0;
  }

  .df-first-container-inner-2 {
    font-size: 6.6vw;
    margin: 0;
    padding: 0;
  }

  .df-first-container-inner-3 {
    font-size: 3.3vw;
    white-space: nowrap;
    margin: 0;
    padding: 0.5em 0 0;
  }
}

.n-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.n-flex-section-1 {
  background-color: #002c75;
  width: 50vw;
  height: auto;
  position: relative;
}

.n-flex-section-2 {
  background-color: #002c75;
  width: 50vw;
  height: 48.7em;
  position: relative;
}

@media (min-width: 500px) and (max-width: 1100px) {
  .n-flex-section-2 {
    height: 55.7em;
  }
}

.n-item {
  position: absolute;
}

.n-item-img {
  display: block;
  max-width: 100%;
  // height: auto;
  max-height: 100%;
}

@media (max-width: 1000px) {
  .n-flex-container {
    display: flex;
    flex-direction: column;
  }

  .n-flex-section-1,
  .n-flex-section-2 {
    width: 100vw;
    justify-content: center;
    display: block;
  }
}

@media (max-width: 499px) {
  .n-flex-section-1 {
    height: 25vh;
  }

  .n-flex-section-2 {
    margin-top: -0.1em;
  }

  .n-item-img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  .button-link-white .button-border-white {
    margin: 0 auto;
    width: 90vw;
  }

  .homeh1 {
    padding-left: 0.2em;
  }

  .homeh3 {
    padding-left: 0.2em;
  }

  .homep {
    padding-left: 0.5em;
  }

  .button-link-white {
    width: 90vw;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
  }
}

.flexed-wrapped {
  display: flex;
  flex-wrap: wrap;
}

.focus-underline:focus {
  text-decoration: underline overline transparent;
}

@media (max-width: 499px) {
  .n-flex-section-2 {
    height: 57.7em;
  }

  .rm-mt-2 {
    margin-top: 2em;
  }

  .rm-mb-3 {
    margin-top: 3em;
  }
}

.notice-box-up {
  font-size: 1.1em;
}

.button-m {
  margin: 2em 0;
}

.thumb img {
  max-width: 100% !important;
}

.checkStatusNav {
  border: 1px solid white;
  border-radius: 4px;
}

.turn-by-directions {
  background-color: #ffffff;
}

.turn-by-directions h4 {
  background-color: #ffffff;
}

.turn-by-directions h5 {
  background-color: #ffffff;
}

.oranglinesFR {
  display: none;
}

@media (min-width: 1400px) {
  .oranglinesFR {
    display: initial;
  }
}

.infoLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-left: auto;
}

.infoLink a {
  background-color: white;
  border-radius: 50px;
  color: #002c75;
  font-family: gentonaBold, Arial, sans-serif;
  font-size: calc(var(--sizeVar)*1.75);
  border: 1px solid transparent;
  outline: none;
  padding: var(--sizeVar) calc(var(--sizeVar)*2);
  cursor: pointer;
  text-decoration: none;
  font-weight: 100;
}

.infoLink a:hover,
.infoLink a:active,
.infoLink a:focus {
  background-color: #002c75;
  color: white;
  border: 1px solid white;
}

@media (max-width: 1349px) {
  .infoLink {
    margin-left: 0;
  }
}

.whiteSpaceNoWrap {
  white-space: nowrap;
}

.textOnyPage {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif !important;
    padding: .5em 0 !important;
    margin: 0 !important;
    border-bottom: none !important;
    font-weight: normal !important;
    list-style: none !important;
    color: black !important;
    text-transform: capitalize !important;
    letter-spacing: normal !important;
    line-height: normal !important;
  }

  h1 {
    font-size: 2em !important;
    font-weight: 700 !important;
  }

  h2 {
    font-size: 1.5em !important;
    font-weight: 700 !important;
  }

  h3 {
    font-size: 1.3em !important;
    font-weight: 700 !important;
  }

  h4 {
    font-size: 1em !important;
    font-weight: 700 !important;
  }

  h5 {
    font-size: 0.8em !important;
    font-weight: 700 !important;
  }

  h6 {
    font-size: 0.7em !important;
    font-weight: 700 !important;
  }

  p {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 1em !important;
    padding: .5em 0 !important;
    margin: 0 !important;
    border-bottom: none !important;
    font-weight: normal !important;
    list-style: none !important;
    color: black !important;
    letter-spacing: normal !important;
    line-height: normal !important;
  }

  ul li {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 1em !important;
    padding: 0 !important;
    border-bottom: none !important;
    font-weight: normal !important;
    list-style: none !important;
    color: black !important;
    letter-spacing: normal !important;
    line-height: normal !important;
    list-style-type: disc !important;
  }

  ul li:before {
    content: none;
    color: black !important;
    display: block;
    margin-right: 0;
    font-size: 0;
    position: relative;
    margin-left: 0;
    margin-top: 0;
  }

  a {
    color: black !important;
    letter-spacing: normal !important;
    line-height: normal !important;
    font-weight: normal !important;
    font-size: 1em !important;
  }
}

.publicationsImg {
  width: 250px;
  -webkit-box-shadow: 3px 5px 15px 1px #878787;
  box-shadow: 3px 5px 15px 1px #878787;
}

@media (max-width: 800px) {
  .publicationsImg {
    width: 250px;
    -webkit-box-shadow: 3px 5px 15px 1px #878787;
    box-shadow: 3px 5px 15px 1px #878787;
    margin-bottom: 3em;
  }
}

.visit-social-icons a>span:hover {
  transform: scale(1.3);
}

.heroText {
  margin: 1vw;
  padding: 0;
}

@media (max-width: 499px) {
  .heroText {
    margin: 10vw;
  }
}

.block,
.dreamText,
.applyText {
  display: block;
}

// .dreamText:after {
//   content: ",";
//   font-family: "gentona-light", Arial, Helvetica, sans-serif;
//   font-style: italic;
//   font-weight: 300;
//   margin-left: -0.2em;
// }

.dreamText2 {
  display: block;
}

.dreamText2:after {
  content: ",";
  font-family: "gentona-light", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 300;
  margin-left: -0.2em;
}

.applyText:after {
  content: ".";
  margin-left: -0.1em;
  font-weight: 300;
}

.buttonLink {
  padding: 1vw 2.3vw;
  border-radius: 3.32vw;
  outline: none;
  text-decoration: none;
  font-size: 1vw;
  font-family: "gentona", Arial, Helvetica, sans-serif;
  font-weight: 700;
  min-width: fit-content;
  text-align: center;
  white-space: nowrap;
  z-index: 10;

  // min-width: 230px;
  @media only screen and (max-width: 499px) {
    font-size: 3.8vw;
    padding: 3.8vw 1.5em;
    border-radius: 7.6vw;
    width: 70vw;
  }
}

.buttonLink-df {
  border-radius: 3.32vw;
  outline: none;
  text-decoration: none;
  font-size: 1vw;
  font-family: "gentona", Arial, Helvetica, sans-serif;
  font-weight: 700;
  min-width: fit-content;
  text-align: center;
  white-space: nowrap;
  z-index: 10;

  // min-width: 230px;
  @media only screen and (max-width: 499px) {
    font-size: 3.8vw;
    border-radius: 7.6vw;
    width: 70vw;
  }
}

.whiteOnBlue {
  background: $uf-r2021-dark-blue;
  color: #fff;
  border: 2px solid $uf-r2021-dark-blue;

  @media only screen and (max-width: 499px) {}
}

.whiteOnBlue.buttonLink:hover,
.whiteOnBlue.buttonLink:focus,
.whiteOnBlue.buttonLink:active {
  background: #fff;
  color: $uf-r2021-dark-blue;
  text-decoration: none;
}

.blueOnTeal {
  background: $uf-r2021-teal;
  color: $uf-r2021-dark-blue;
  border: 2px solid $uf-r2021-teal;

  @media only screen and (max-width: 499px) {
    display: block;
    width: 70vw;
  }
}

.blueOnTeal.buttonLink:hover,
.blueOnTeal.buttonLink:focus,
.blueOnTeal.buttonLink:active {
  background: $uf-r2021-dark-blue;
  color: $uf-r2021-teal;
  text-decoration: none;
}

.blueOnWhite {
  background: #fff;
  color: $uf-r2021-dark-blue;
  border: 2px solid #fff;

  @media only screen and (max-width: 499px) {
    display: block;
    width: 70vw;
  }
}

.blueOnWhite.buttonLink:hover,
.blueOnWhite.buttonLink:focus,
.blueOnWhite.buttonLink:active {
  background: $uf-r2021-dark-blue;
  color: #fff;
  text-decoration: none;
}

.buttonRow {
  gap: 1vw;
}

.rowToColumnTablet {
  @media only screen and (max-width: 711px) {
    flex-direction: column;
    gap: 2.6vw;
  }
}

.mfosRainSVG {
  margin: 0 auto;
  width: 58vw;
  height: 48vw;
  transform: translateY(-12.5vw);
  margin-bottom: -27vw;
}

span.dfBlockMobile {
  @media only screen and (max-width: 499px) {
    display: block;
  }
}

.bt_row {
  margin-left: 1.5em;
}

@media screen and (min-width: 712px) and (max-width: 1365px) {
  .bt_row {
    margin-top: 4em;
  }
}

@media screen and (max-width: 800px) {
  .bt_row {
    margin-left: 0;
  }
}

.honors-img {
  width: 35vw;
  border: 10px solid white;
}

@media screen and (max-width: 800px) {
  .honors-img {
    width: 75vw;
    border: none;
  }
}

.mobile-left {
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .mobile-left {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.percent3 {
  font-family: 'obviously-wide';
  color: #fa4616;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 2.8vw;
}

@media screen and (max-width: 499px) {
  .percent3 {
    text-align: left;
    font-size: 22.8vw;
  }
}

// Import

.preFootPaddingBottom {
  padding-bottom: 7vh;
}

.wholeWidth {
  width: 100vw;
}

.flexAlignItemsCenter {
  display: flex;
  align-items: center;
}

.flexJustifyCenter {
  display: flex;
  justify-content: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 499px) {
  .hiddenMobile {
    display: none;
  }
}

@media only screen and (min-width: 500px) {
  .hiddenDesktop {
    display: none;
  }
}

.blueText {
  color: $uf-r2021-dark-blue;
}

.orangeText {
  color: $uf-r2021-orange;
}


.footerIconRow {
  gap: 2em;
  padding-top: 2em;
  padding-bottom: 1em;
}

.gallery-container {
  width: 80%;
  margin: 2rem auto;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  grid-template-rows: repeat(18, 1.6vw);
  grid-gap: .5rem;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  z-index: 100;
}

.oos .gallery__img-mobile {
  width: 100%;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 7;
}

.gallery__item--2 {
  grid-column-start: 9;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 9;
}

.gallery__item--3 {
  grid-column-start: 13;
  grid-column-end: 23;
  grid-row-start: 1;
  grid-row-end: 9;
}

.gallery__item--4 {
  grid-column-start: 23;
  grid-column-end: 28;
  grid-row-start: 1;
  grid-row-end: 9;
}

.gallery__item--5 {
  grid-column-start: 28;
  grid-column-end: 32;
  grid-row-start: 1;
  grid-row-end: 9;
}

.gallery__item--6 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 7;
  grid-row-end: 13;
}

.gallery__item--7 {
  grid-column-start: 9;
  grid-column-end: 16;
  grid-row-start: 9;
  grid-row-end: 14;
}

.gallery__item--8 {
  grid-column-start: 16;
  grid-column-end: 23;
  grid-row-start: 9;
  grid-row-end: 14;
}

.gallery__item--9 {
  grid-column-start: 23;
  grid-column-end: 32;
  grid-row-start: 9;
  grid-row-end: 14;
}

.gallery__item--10 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 13;
  grid-row-end: 19;
}

.gallery__item--11 {
  grid-column-start: 9;
  grid-column-end: 16;
  grid-row-start: 14;
  grid-row-end: 19;
}

.gallery__item--12 {
  grid-column-start: 16;
  grid-column-end: 22;
  grid-row-start: 14;
  grid-row-end: 19;
}

.gallery__item--13 {
  grid-column-start: 22;
  grid-column-end: 25;
  grid-row-start: 14;
  grid-row-end: 19;
}

.gallery__item--14 {
  grid-column-start: 25;
  grid-column-end: 32;
  grid-row-start: 14;
  grid-row-end: 19;
}

@media only screen and (max-width: 499px) {
  .galleryMobile {
    margin: 1em;
  }
}

.modal img:not(.modalClose) {
  width: 50%;
  object-fit: cover;
}

.hideG {
  display: none;
}

.forwardGalleryButton {
  width: 80px !important;
  position: absolute;
  top: 50%;
  right: 80px;
  left: auto;
}

.backGalleryButton {
  width: 80px !important;
  position: absolute;
  top: 50%;
  left: 80px;
  right: auto;
}

.forwardGalleryButton:hover {
  opacity: .8;
}

.backGalleryButton:hover {
  opacity: .8;
}

.modal {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .733);
  margin-top: -1px;
  animation: zoom .3s ease-in-out;
  z-index: 100;
}

.modalClose {
  width: 40px;
  position: absolute;
  top: 5%;
  right: 20px;
  left: auto;
}

.df-blue-stat {
  letter-spacing: -.3px;
  color: #07317b;
  opacity: 1;
  font-size: 2.2vw;
  font-family: gentona-bold;
  font-weight: 600;
  margin: 0;
  padding: 1em 0 0 0;
}

@media only screen and (max-width: 499px) {
  .df-blue-stat {
    letter-spacing: -.3px;
    color: #07317b;
    opacity: 1;
    font-size: 5.2vw;
    font-family: gentona-bold;
    font-weight: 600;
    margin: 0;
    padding: 1em;
  }
}

.min-max-stat {
  min-width: 12vw;
  height: 35vh;
}

.svt-flex-wrap {
  flex-wrap: nowrap;
}

@media only screen and (max-width: 1700px) {
  .svt-flex-wrap {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 499px) {
  .svt-flex-wrap {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1700px) {
  .min-max-stat {
    width: 10vw;
    height: auto;
    padding: 0 0 2em 0;
  }
}

@media only screen and (max-width: 499px) {
  .min-max-stat {
    width: 84vw;
    height: auto;
    padding: 0 0 2em;
  }
}

.df-text-center2 {
  text-align: center;
}

@media (max-width: 499px) {
  .df-text-center2 {
    text-align: center;
  }
}

.campusGalleryText {
  font-size: 1.4vw;
  margin: 0px auto 2.5vw;
}

@media only screen and (max-width: 499px) {
  .campusGalleryText {
    font-size: 4.4vw;
  }
}

@media screen and (max-width: 2550px) {
  .commonGatorsContainer2 {
    padding-top: calc(75px + 75 * ((100vw - 834px) / 1716));
    padding-right: calc(5px + 75 * ((100vw - 834px) / 1716));
    padding-left: calc(25px + 75 * ((100vw - 834px) / 1716));
    padding-bottom: calc(75px + 75 * ((100vw - 834px) / 1716));
  }
}

/* Fix PaCE sidenav issues*/
// .collapsible.pace-sidenav-collapse:after {
//   color: #002c75 !important;
//   background-color: transparent !important;
//   z-index: 2;
//   line-height: 0;
// }

// .collapsible:is(.pace-sidenav-collapse:hover,
// .pace-sidenav-collapse:focus, 
// .pace-sidenav-collapse:active) {
//   color: #002c75 !important;
//   background-color: transparent !important;
// }

// .pace-sidenav-collapse.active:focus:after {
//   content: "\0354" !important;
//   background-color: transparent !important;
//   transform: rotate(450deg) translateX(35px) translateY(-30px);
// }

@media only screen and (max-width: 499px) {
  .heroContainer {
    padding-top: 0;
    padding-bottom: 0;
  }

  .heroTextContainer {
    position: relative;
    text-align: center;
    padding: 2em 0;
  }

  .heroImage {
    max-width: 100%;
    object-fit: contain;
    width: 100vw;
    padding-top: 6.5em;
  }
}

@media only screen and (max-width: 499px) {

  html,
  body {
    // max-width: 100%;
    // overflow-x: hidden;
  }
}

// countdown clock 2024
// adapted from Responsive Flipclock Countdown Annika Külzer
.flipclock {
  display: flex;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
  padding-top: 7em;
}

.flipclock * {
  box-sizing: inherit;
}

.leaf {
  display: flex;
  flex-direction: column;
  margin: 0 4px;
  perspective: 300px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .5);
}

.leaf::after {
  content: attr(data-label);
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 8px;
  transform: translateX(-50%);
  color: #07317B;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "gentona-bold", sans serif;
}

.top,
.bottom,
.leaf-front,
.leaf-back {
  position: relative;
  display: block;
  height: 64px;
  width: 120px;
  background-color: white;
  color: #07317B;
  overflow: hidden;
  border-color: #2d3748;
}

.top span,
.bottom span,
.leaf-front span,
.leaf-back span {
  position: absolute;
  left: 50%;
  width: 100%;
  height: 128px;
  text-align: center;
  font-family: "obviously-variable", sans-serif;
  font-variation-settings: "ital" 0, "wdth" 70, "wght" 600;
  font-size: 80px;
  line-height: 128px;
  transform: translateX(-50%);
  font-weight: 500;
}

.leaf._3-digits .top,
.leaf._3-digits .bottom,
.leaf._3-digits .leaf-front,
.leaf._3-digits .leaf-back {
  width: 148px;
}

.top,
.leaf-front {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.top span,
.leaf-front span {
  top: 10px;
}

.leaf-front {
  position: absolute;
  z-index: 10;
}

.bottom,
.leaf-back {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-style: solid;
  border-top-width: 1px;
}

.bottom span,
.leaf-back span {
  bottom: -10px;
}

.leaf-back {
  position: absolute;
  top: 64px;
  z-index: 10;
}

@media screen and (max-width: 600px) {
  .flipclock {
    padding: 6.6666666667vw;
  }

  .leaf {
    margin: 0 0.6666666667vw;
  }

  .leaf::after {
    margin-top: 1.3333333333vw;
    font-size: 2.3333333333vw;
  }

  .top,
  .bottom,
  .leaf-front,
  .leaf-back {
    height: 10.6666666667vw;
    width: 20vw;
  }

  .top span,
  .bottom span,
  .leaf-front span,
  .leaf-back span {
    height: 21.3333333333vw;
    font-size: 13.3333333333vw;
    line-height: 21.3333333333vw;
  }

  .leaf._3-digits .top,
  .leaf._3-digits .bottom,
  .leaf._3-digits .leaf-front,
  .leaf._3-digits .leaf-back {
    width: 24.6666666667vw;
  }

  .top,
  .leaf-front {
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
  }

  .bottom,
  .leaf-back {
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
  }

  .leaf-back {
    top: 10.6666666667vw;
  }
}

.leaf-front {
  transform-origin: bottom center;
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  transition-delay: 0.3s;
}

.leaf-back {
  transform-origin: top center;
  transform: rotateX(90deg);
  transform-style: preserve-3d;
  transition-delay: 0s;
}

.bottom::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: black;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  filter: blur(10px);
}

.flip .leaf-front {
  transform: rotateX(-90deg);
  transition: transform 0.3s ease-in, background-color 0.3s ease-in, color 0.3s ease-in;
  transition-delay: 0s;
  color: #07317B;
  background-color: #737373;
}

.flip .leaf-back {
  transform: rotateX(0deg);
  transition: transform 0.3s ease-in, background-color 0.3s ease-in, color 0.3s ease-in;
  transition-delay: 0.3s;
}

.flip .bottom::before {
  transition: height 0.3s ease-in-out;
  transition-delay: 0.15s;
  height: 100%;
}